import React, { useState, useEffect } from "react";
import { Row, Col, Input, Modal, Spin } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import PositionBoxList from "./PositionBoxList";

const PositionBoxListModel = ({ id, title, children }) => {

    const [visible, setVisible] = useState(false);

    const onCancel = () => {
        setVisible(false)
    }


    return (
        <>
            <a href="#" onClick={() => setVisible(true)}>{children}
            </a>
            <Modal
                title={title}
                visible={visible}
                footer={null}
                width="60%"
                destroyOnClose={true}
                onCancel={onCancel}
            >
                <ModuleContent>
                    <PositionBoxList id={id} canDelete={false}/>
                </ModuleContent>
            </Modal>
        </>
    );
};

export default PositionBoxListModel;
