import React, { Component } from 'react'
import { Input, Button, message,  Row, Col, Form } from 'antd'
import API from '../../services/Api'
import { withRouter } from 'react-router-dom'
import ImageUpload from '../../components/ImageUpload'
import BasicLayout,{ ModuleContent, ModuleHeader } from '../../layouts/basic/BasicLayout'
import HtmlEditor from '../../components/HtmlEditor'
import BraftEditor from 'braft-editor'
import SpinBox from '../../components/SpinBox'
import { defaultFormLayout } from '../../configs/config'
import style from './cms.module.less'
import DictSelect from "../../components/DictSelect";
class BulletinEdit extends Component {
    formRef = React.createRef()

    state = {
        editMode: false,
        loading: true
    }

    componentDidMount() {
        const { params } = this.props.match

        if (params.id) {
            this.setState({
                editMode: true
            })
            this.fetch(params.id)
        } else {
            this.setState({ loading: false })
        }
    }

    fetch(id) {
        API.post(`/contents/get`, {
            id: id
        }).then(res => {
            const data = res.data

            this.formRef.current.setFieldsValue({
                title: data.title,
                keyId: data.keyId,
                image: data.image,
                contentCategory:data.contentType,
                contents: {
                    editorState: BraftEditor.createEditorState(data.contents)
                }
            })
            this.setState({ loading: false })
        })
    }

    handleSubmit = values => {
        if (this.state.editMode) {
            values.id = this.props.match.params.id
        }

        values.contents = values.contents.editorState.toHTML()

        values.imageId = values.image.id;
        values.contentType = values.contentCategory.id;

        API.post(`/bulletin/save`, values).then(res => {
            message.success('保存成功')
            this.props.history.goBack()
        })
    }



    render() {
        const { editMode, loading } = this.state

        return (
            <BasicLayout>
                <ModuleContent>
                    <ModuleHeader
                        title="内容编辑"
                    />
                    <div className={style.form_content}>
                        <Form
                            {...defaultFormLayout.itemLayout}
                            ref={this.formRef}
                            onFinish={this.handleSubmit}
                        >
                            <SpinBox loading={loading}>
                                <Form.Item
                                    label="标题"
                                    name="title"
                                    rules={[
                                        { required: true, message: '标题必填' }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                {/* <Form.Item
                                    label="keyId"
                                    name="keyId"
                                    rules={[
                                        { required: true, message: 'keyId必填' }
                                    ]}
                                >
                                    <Input readOnly={editMode} />
                                </Form.Item> */}
                                
                                <Form.Item
                                label="内容类型"
                                name="contentCategory"
                                rules={[
                                    { required: true, message: '内容类型必填' }
                                ]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                >
                                <DictSelect
                                    selectFirst={!editMode}
                                    disabled={false}
                                    categoryKey="CONTENT_CATEGORY"
                                />
                            </Form.Item>

                                <Form.Item
                                    label="主题图片"
                                    name="image"
                                    rules={[
                                        {
                                            required: true,
                                            message: '图片必须选择'
                                        }
                                    ]}
                                >
                                    <ImageUpload />
                                </Form.Item>
                                <Form.Item
                                    {...defaultFormLayout.contentsLayout}
                                    label="内容"
                                    name="contents"
                                    rules={[
                                        { required: true, message: '内容必填' }
                                    ]}
                                >
                                    <HtmlEditor />
                                </Form.Item>

                                <Form.Item
                                    {...defaultFormLayout.submitBtLayout}
                                >
                                    <Button type="primary" htmlType="submit">
                                        保存
                                    </Button>
                                </Form.Item>
                            </SpinBox>
                        </Form>
                    </div>
                </ModuleContent>
            </BasicLayout>
        )
    }
}

export default withRouter(BulletinEdit)
