import moment from "moment";
const Util = {

    imageStyle: function(url, style){
        return url+"?x-oss-process=style/"+style
    },

    currency: function(amount, fixed = 2){
        const data2 = amount.toFixed(fixed).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,');
        return "￥"+data2;
    },

    currencyFen: function(fenAmount, fixed = 2){
        const data2 = (fenAmount/100).toFixed(fixed).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,');
        return "￥"+data2;
    },

    dateRange: function(number, type){
        return [
            moment().subtract(number, type || "days").format("YYYY-MM-DD 00:00:00"),
            moment().add(0, type || "days").format("YYYY-MM-DD 23:59:59")
        ]
    }
}

export default Util;
