import React, { useState } from "react";
import {
    Input,
    Button,
    Switch,
    Form,
    InputNumber,
    Popconfirm,
    message,
    Divider,
    DatePicker,
} from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader,
} from "../../layouts/basic/BasicLayout";
import { useParams, useHistory } from "react-router-dom";
import useForm from "../../hooks/useForm";
import style from "./merchant.module.less";
import SpinBox from "../../components/SpinBox";
import DataSelect from "../../components/DataSelect";
import Main from "../share/Main";
import API from "../../services/Api";
import moment from "moment";

const { RangePicker } = DatePicker;

const MerchantEdit = () => {
    let history = useHistory();
    const [admin, setAdmin] = useState(null);
    const [autoTransfer, setAutoTransfer] = useState(false);
    const [autoRefund, setAutoRefund] = useState(false);

    const onFormLoad = (form, data) => {
        setAutoTransfer(data.autoTransfer);
        setAutoRefund(data.autoRefund);
        if(data.transferLimit && data.transferLimit != ""){
            data.transferLimit = data.transferLimit / 100;
        }else{
            data.transferLimit = 0;
        }
        
        form.setFieldsValue(data);
        form.setFieldsValue({
            rangePicker: [
                moment(data.contractStartTime),
                moment(data.contractEndTime),
            ],
        });
        if (data.admin) {
            form.setFieldsValue({
                admin: { id: data.admin.id, title: data.admin.userName },
            });
            setAdmin(data.admin);
        }
    };

    const onBeforeSave = (form, values) => {
        values.startTime = moment(values["rangePicker"][0]).format(
            "YYYY-MM-DD HH:mm:ss"
        );
        values.endTime = moment(values["rangePicker"][1]).format(
            "YYYY-MM-DD HH:mm:ss"
        );
        if (values.admin) {
            values.adminId = values.admin.id;
        }
        if(values.transferLimit && values.transferLimit >=0){
            values.transferLimit = values.transferLimit * 100;
        }else{
            values.transferLimit = 0;
        }
        
        values.rangePicker = "";
    };

    const { formProps, loading, editMode, submitBtLayout, id } = useForm({
        id: useParams().id,
        formLoadUrl: "/merchants/get",
        saveUrl: "/merchants/save",
        saveMessage: "保存成功!",
        onBeforeSave: onBeforeSave,
        onFormLoad: onFormLoad,
    });

    const deleteData = () => {
        API.post("/merchants/delete", { id: id }).then((res) => {
            message.success("删除成功");
            history.goBack();
        });
    };

    const resetAdmin = () => {
        API.post("/merchants/resetAdmin", { id: id }).then((res) => {
            message.success("重置成功");
            document.location.reload();
        });
    };

    const autoRefundChange = (e) => {
        setAutoRefund(e);
    };

    const autoTransferChange = (e) => {
        setAutoTransfer(e);
    };

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="商户编辑" />

                <Form {...formProps} className={style.form_content}>
                    <SpinBox loading={loading}>
                        <Form.Item
                            label="名称"
                            name="title"
                            rules={[{ required: true, message: "名称必填" }]}
                        >
                            <Input disabled={!Main.isAdmin()}/>
                        </Form.Item>

                        <Form.Item
                            label="备注"
                            name="remark"
                            rules={[
                                { required: true, message: "商户备注必填" },
                            ]}
                        >
                            <Input disabled={!Main.isAdmin()} />
                        </Form.Item>

                        <Form.Item
                            label="平台分成比例"
                            name="platformRate"
                            rules={[
                                {
                                    required: Main.isAdmin(),
                                },
                            ]}
                        >
                            <InputNumber
                                min={0.001}
                                max={0.999}
                                disabled={!Main.isAdmin()}
                            />
                        </Form.Item>

                        <Form.Item
                            label="自动退款"
                            name="autoRefund"
                            valuePropName="checked"
                        >
                            <Switch
                                disabled={!Main.isAdmin()}
                                onChange={autoRefundChange}
                            />
                        </Form.Item>

                        <Form.Item
                            label="自动提现"
                            name="autoTransfer"
                            valuePropName="checked"
                        >
                            <Switch
                                disabled={!Main.isAdmin()}
                                onChange={autoTransferChange}
                            />
                        </Form.Item>

                        {autoTransfer ? (
                            <Form.Item
                                label="每日分润限额"
                                name="transferLimit"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={1}
                                    max={4999}
                                    disabled={!Main.isAdmin()}
                                />
                            </Form.Item>
                        ) : (
                            ""
                        )}

                        
                        <Form.Item
                            label="收款账户"
                            name="cardNumber"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input disabled={!Main.isAdmin()}/>
                        </Form.Item>                        

                        <Form.Item
                            label="合同时间"
                            name="rangePicker"
                            wrapperCol={{ span: 16 }}
                            rules={[{ required: true, message: "时间必填" }]}
                        >
                            <RangePicker showTime disabled={!Main.isAdmin()} />
                        </Form.Item>

                        <Form.Item
                            label="地址"
                            name="address"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input disabled={!Main.isAdmin()}/>
                        </Form.Item>

                        <Form.Item label="管理员" name="admin">
                            <DataSelect
                                dataUrl="/admins/merchantListAll"
                                disabled={!Main.isAdmin()}
                            />
                        </Form.Item>

                        <Divider></Divider>

                        <Form.Item label="客服电话" name="phone">
                            <Input disabled={!Main.isMerchant()} />
                        </Form.Item>
                        <Form.Item
                            label="联系电话"
                            name="contact"
                            rules={[
                                {
                                    required: Main.isMerchant(),
                                },
                            ]}
                        >
                            <Input disabled={!Main.isMerchant()} />
                        </Form.Item>

                        <Form.Item {...submitBtLayout}>
                            {Main.isAdmin() || Main.isMerchant() ? (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    style={{ marginRight: 8 }}
                                >
                                    保存
                                </Button>
                            ) : (
                                ""
                            )}

                            {editMode && Main.isAdmin() ? (
                                <>
                                    <Divider type="vertical" />
                                    <Popconfirm
                                        placement="bottomRight"
                                        title="确定要删除吗? 该操作无法恢复"
                                        onConfirm={deleteData}
                                        okText="删除"
                                        cancelText="取消"
                                    >
                                        <Button danger type="primary">
                                            删除
                                        </Button>
                                    </Popconfirm>
                                </>
                            ) : (
                                ""
                            )}

                            {admin && Main.isAdmin() && editMode ? (
                                <>
                                    <Divider type="vertical" />
                                    <Popconfirm
                                        placement="bottomRight"
                                        title="确定重置管理员? 该操作无法恢复"
                                        onConfirm={resetAdmin}
                                        okText="重置"
                                        cancelText="取消"
                                    >
                                        <Button type={"danger"}>
                                            重置管理员
                                        </Button>
                                    </Popconfirm>
                                </>
                            ) : (
                                ""
                            )}
                        </Form.Item>
                    </SpinBox>
                </Form>
            </ModuleContent>
        </BasicLayout>
    );
};

export default MerchantEdit;
