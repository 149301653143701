import React, { Component } from 'react';
import style from './component.module.less';
import Account from "../services/Account";


const AuthRole = ({ authKey, children}) => {

    const authoritys = Account.info("accountAuthoritys") || [];
    const authed = authoritys.includes(authKey);

    return authed ? children : "";
}

export default AuthRole;

