
import React from 'react';
import { Result, Button } from 'antd';
import AccountLayout from '../../layouts/account/AccountLayout';
import API from '../../services/Api';
import { withRouter } from 'react-router-dom';
import Account from '../../services/Account';
import styles from './account.module.less';


const NotFound =() => {

    const goBack = values => {
        this.props.history.push('/myWork/dashboard');
    }


    return (
        <AccountLayout>

            <Result
                status="404"
                title="404"
                subTitle="对不起，您访问的页面不存在"
                extra={<Button type="primary" onClick={goBack}>返回主页</Button>}
            />
        </AccountLayout>
    );
}



export default withRouter(NotFound);
