import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const DateRangeSelect = ({ value, onChange, ...restProps }) => {

    const startFormat = "YYYY-MM-DD 00:00:00";
    const endFormat = "YYYY-MM-DD 23:59:59";

    const toAntValue = dateStrings => {
        return dateStrings ? [moment(dateStrings[0], startFormat), moment(dateStrings[1], endFormat)] : null;
    };

    const toMyValue = v => {
        return v ? [moment(v[0]).format(startFormat), moment(v[1]).format(endFormat)] : null ;
    };

    const handleChange = ( dates, dateStrings) => {

        if (onChange) {
            onChange(toMyValue(dates));
        }
    };

    return (
        <RangePicker

            format="YYYY-MM-DD"
            allowClear={false}
            onChange={handleChange}
            value={toAntValue(value)}
            {...restProps}
            ranges={{
                '今天': [moment(), moment()],
                '本周': [moment().startOf('week'), moment().endOf('week')],
                '本月': [moment().startOf('month'), moment().endOf('month')],
                '最近7天': [moment().subtract('days', 6), moment()],
                '最近30天': [moment().subtract('days', 30), moment()],
            }}
        />
    );
};

export default DateRangeSelect;
