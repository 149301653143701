import React, { useState ,useEffect} from "react";
import { Row, Col, Input, Modal, Spin } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import { Link } from "react-router-dom";
import DispatchBoxTransfer from "./DispatchBoxTransfer";
import DictSelect from "../../components/DictSelect";

const DispatchBoxModel = ({disVisible, handleCancel, pid, stubs}) => {
    const modelCancel = () => {
        if(handleCancel) {
            handleCancel()
        }
    };
    
    return (
        <>
            <Modal
                title="投放"
                visible={disVisible}
                footer={null}
                width="60%"
                onCancel={modelCancel}
            >
                <ModuleContent>
                    <DispatchBoxTransfer stubs={stubs} pid={pid} />

                </ModuleContent>
            </Modal>
        </>
    );
};

export default DispatchBoxModel;
