import { useState, useCallback, useEffect } from "react";
import API from "../services/Api";

function useTable({
    url,
    paging = true,
    pageSize = 10,
    queryParams,
    onPageChange,
    onDataSourceLoad,
    onTableChange
}) {
    const [dataSource, setDataSource] = useState(null);

    const [pagination, setPagination] = useState(null);

    const [loading, setLoading] = useState(false);

    const onChange = (pagination, filters, sorter) => {
        if(onPageChange){
            onPageChange(pagination.current-1);
        }
        if(onTableChange){
            onTableChange(pagination, filters, sorter);
        }
    }

    const queryDeps = queryParams
        ? Object.keys(queryParams).map((key) => queryParams[key])
        : [];

    
    const showTotal = (total, range) => { return `共 ${total} 项`};

    const fetch = () => {
        setLoading(true);

        const urlParams = !paging
            ? queryParams
            : {
                size: pageSize,
                ...queryParams,
              };


        API.post(url, urlParams)
            .then((res) => {
                const data = res.data;
                console.log(data)
                let list = paging ? data.content || data.results : data;
                console.log(list)
                list.map((item) => {
                    item.key = "key-" + item.id;
                });
                

                if (onDataSourceLoad) {//如果有初始数据需要处理
                    const newList = onDataSourceLoad(list)
                    setDataSource(newList);
                } else {
                    setDataSource(list);
                }

                if (paging) {
                    setPagination({
                        current:
                            (Number.isInteger(data.number)
                                ? data.number
                                : data.offset / data.limit) + 1,
                        pageSize: Number.isInteger(data.number)
                            ? data.size
                            : data.limit,
                        total: Number.isInteger(data.number)
                            ? data.totalElements
                            : data.total,
                        showSizeChanger: false,
                        showTotal: showTotal
                    });
                }

                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetch();
    }, [...queryDeps]);

    const reloadTable = () => {
        fetch()
    };

    return {
        tableProps: {
            dataSource,
            size: "default",
            rowKey: "id",
            onChange: onChange,
            pagination: paging ? pagination : false,
            loading,
        },
        loading,
        setLoading,
        reloadTable
    };
}

export default useTable;
