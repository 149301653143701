import React, { useState} from 'react'
import { Table, Row, Col, Input} from 'antd'
import BasicLayout, {
    ModuleContent,
    ModuleTool
} from '../../layouts/basic/BasicLayout'
import useTable from '../../hooks/useTable'
import style from "./sms.module.less";
import Main from "../share/Main";
import DateRangeSelect from "../../components/DateRangeSelect";
import Util from "../../services/Util";


const SmsList = () => {
    const [queryWord, setQueryWord] = useState('')
    const [page, setPage] = useState(0);
    const [dateRange, setDateRange] = useState(Util.dateRange(3, "days"))
                        
    const columns = [
        {
            title: '用户名',
            dataIndex: ['mobile'],
            render: (text, record) => (
                <span>{Main.isAdmin() ? record.mobile:(record.mobile.substr(0,3) + "****" + record.mobile.substr(7,4))}</span>
            )
        },
        {
            title: '商户',
            dataIndex: ['merchant','name']
        },
        {
            title: '内容',
            dataIndex: ['verifyCode']
        },
        {
            title: '发送时间',
            dataIndex: ['sendTime']
        },
        {
            title: '发送状态',
            dataIndex: ['success'],
            render: (text, record) => (
                <span>{record.success ? "发送成功":"发送失败"}</span>
            )
        },
       
    ]

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    }

    const { tableProps, reloadTable } = useTable({
        url: '/verifyCodes/query',
        pageSize: 10,
        queryParams: {
            page,
            queryWord,
            startDate: dateRange[0],
            endDate: dateRange[1]
        },
        onPageChange : (page) =>{
            setPage(page);
        }
    })
    
    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <Input.Search
                            placeholder="请搜索"
                            onSearch={g => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} />
            </ModuleContent>
        </BasicLayout>
    )
}

export default SmsList
