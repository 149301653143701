import React, { useState, useEffect } from "react";
import { Transfer, Button, Popconfirm, message } from "antd";
import BasicLayout from "../../layouts/basic/BasicLayout";
import API from "../../services/Api";

const DispatchBoxTransfer = ({ stubs, pid}) => {
    // const [mockData, setMockData] = useState([]);
    const [mockData, setMockData] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);

    const fetch = () => {
        let mockDataList = [];
        stubs.map((item) => {
            const data = {
                key: item.id,
                title: item.title,
                description: item.uniqueId,
            };
            mockDataList.push(data);
        });
        setMockData(mockDataList);
    };

    useEffect(() => {
        fetch();
    }, []);

    const filterOption = (inputValue, option) =>
        option.uniqueId.indexOf(inputValue) > -1;

    const handleChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    const handleSearch = (dir, value) => {
        // console.log(newData);
    };
    const onConfirm = () => {
        if (targetKeys.length < 1) {
            message.error("请先选择设备");
        } else {
            API.post("/positions/dispatchStubs", {
                positionId: pid,
                stubIds: targetKeys,
                remark: "",
            }).then((res) => {
                message.success("保存成功！")
                window.location.reload();
            });
        }
    };

    return (
        <>
            <Transfer
                dataSource={mockData}
                showSearch
                filterOption={filterOption}
                targetKeys={targetKeys}
                onChange={handleChange}
                onSearch={handleSearch}
                render={(item) => item.title}
                listStyle={{
                    width: "45%",
                    height: 300,
                }}
            />

            <Popconfirm
                placement="bottomRight"
                title="确认保存? "
                onConfirm={onConfirm}
                okText="确认"
                cancelText="取消"
            >
                <Button
                    type="primary"
                    style={{ marginRight: 8, marginTop: 10 }}
                >
                    保存
                </Button>
            </Popconfirm>
        </>
    );
};

export default DispatchBoxTransfer;
