import React, { useState } from "react";
import { Popconfirm, Form, Input } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader,
} from "../../layouts/basic/BasicLayout";
import { useParams, useHistory } from "react-router-dom";
import useForm from "../../hooks/useForm";
import style from "./fee.module.less";
import SpinBox from "../../components/SpinBox";
import Main from "../share/Main";
import API from "../../services/Api";

const WithdrawalDetail = () => {
    let history = useHistory();

    const onFormLoad = (form, data) => {
        form.setFieldsValue({
            id: data.id,
            invoiceNumber: data.invoiceNumber,
            orderNumber: data.orderNumber,
            // platformRate: data.platformRate,
            // parentRate: data.parentRate,
            statusTitle: data.status.title,
            merchantNamne: data.merchant.title,
            fee: (data.fee / 100).toFixed(2) ,
            cardNumber: data.cardNumber,
            finishTime: data.finishTime,
        });
    };

    const { formProps, loading, submitBtLayout, editMode, id } = useForm({
        id: useParams().id,
        formLoadUrl: "/withDraws/get",
        onFormLoad: onFormLoad,
    });

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="提现记录详情" />

                <Form {...formProps} className={style.form_content}>
                    <SpinBox loading={loading}>
                        <Form.Item label="提现单号" name="invoiceNumber">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="订单号" name="orderNumber">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="状态" name="statusTitle">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="提现商户" name="merchantNamne">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="金额" name="fee">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="收款账户" name="cardNumber">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="到账时间" name="finishTime">
                            <Input disabled={true}></Input>
                        </Form.Item>
                    </SpinBox>
                </Form>
            </ModuleContent>
        </BasicLayout>
    );
};

export default WithdrawalDetail;
