import React, { useState, useEffect } from "react";
import { Row, Col, Table, Space, Button } from "antd";
import API from "../../services/Api";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import style from "./report.module.less";
import moment from "moment";
import Util from "../../services/Util";
import DayColumnChart from "./DayColumnChart";
import BlockTitle from "../../components/BlockTitle";
import DateRangeSelect from "../../components/DateRangeSelect";
import Main from "../share/Main";
import ExportJsonExcel from "js-export-excel";
import { Typography,Tag} from 'antd';

const { Text} = Typography;
const MerchantMonthReport = () => {
    const [dateRange, setDateRange] = useState([
        moment().subtract(3, "month"),
        moment(new Date()),
    ]);

    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);

    const renderContent = (id, record, idx, children) => {
        let obj = {
            children: children,
            props: {},
        }

        let rowSpans = reportData.filter((item) => (
            item.day == record.day
        )).length;

        obj.props.rowSpan = rowSpans;

        if (idx > 0 && (reportData[idx - 1].day == reportData[idx].day)) {
            obj.props.rowSpan = 0;
        }

        return obj;
    }

    const columns = [
        {
            title: "日期",
            dataIndex: "day",  
            render: (id, record, idx) => {
                let content = 
                <>
                    <Text strong>{record.day}</Text>                                                      
                </>                            
                return renderContent(id, record, idx, content);
            }          
        },
        {
            title: "商户",
            dataIndex: "merchantTitle",
            render: (text, record) => <Text code>{record.merchantTitle}</Text>,   
        },
        {
            title: "月收入",
            dataIndex: "fee",
            render: (text, record) => (
                <Tag  color="#108ee9" >{Util.currencyFen(text, 2)}</Tag> 
            )
        },
        {
            title: "总收入",
            dataIndex: "totalFee",
            render: (text, record) => (
                <Tag  color="#108ee9" >{Util.currencyFen(text, 2)}</Tag> 
            )
        },
    ];
    const fetch = () => {
        const values = {
            startDate: moment(dateRange[0]).format("YYYY-MM-DD 00:00:00"),
            endDate: moment(dateRange[1]).format("YYYY-MM-DD 23:59:59"),
        };

        API.post("/report/merchantReports/queryByMonth", values).then((res) => {
            setReportData(res.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetch();
    }, [dateRange]);

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);    
    };

    const downloadExcel = () => {
        var option={};
        let dataTable = [];
        if (reportData) {
            for (let i in reportData) {
                let obj = {
                '日期': reportData[i].day.slice(0, 7),
                '商户': reportData[i].merchantTitle,
                '月收入': Util.currencyFen(reportData[i].fee, 2),
                '总收入': Util.currencyFen(reportData[i].totalFee, 2),
                }
                dataTable.push(obj);
            }
        }
        option.fileName = '商户收入月报表-'+ dataTable[0].日期.slice(0, 7);
        option.datas=[
            {
            sheetData:dataTable,
            sheetName:'商户收入月报表',
            sheetFilter:['日期','商户','月收入','总收入'],
            sheetHeader:['日期','商户','月收入','总收入'],
            }
        ];

        var toExcel = new ExportJsonExcel(option); 
        toExcel.saveExcel();
    };


    
    return (
        <>
            <ModuleTool>
                <Row>
                    <Col flex="1">
                        <span className={style.report_title}>商户收入月报表</span>
                    </Col>

                    <Col flex="600px" className={style.query_area}>
                        <Space>
                            <span>日期区间</span>
                            <DateRangeSelect
                                picker={"month"}
                                value={dateRange}
                                onChange={dateRangeChange}
                            />
                        </Space>
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                {
                    <DayColumnChart
                    title="收入金额趋势图"
                    data={reportData}
                    yField="fee"
                    yAlias="收入金额"
                  
                    currency={0}
                    type="day"
                />
                }

                {
                    <DayColumnChart
                    title="总金额趋势图"
                    data={reportData}
                    yField="totalFee"
                    yAlias="总金额"
                   
                    currency={0}
                    type="day"
                />
                }
                                
                <BlockTitle>
                商户月收入报表
                    <Button type="primary" style={{float:'right', marginRight:'30px'}} onClick={downloadExcel}>导出</Button>
                </BlockTitle>

                <Table
                    dataSource={reportData}
                    size="default"
                    columns={columns}
                    rowKey="day"
                    pagination={false}
                    loading={loading}
                />
            </ModuleContent>
        </>
    );
};

export default MerchantMonthReport;
