import React, { Component } from 'react'

import { Row, Col, Input, Button, message,  Form } from 'antd'
import API from '../../services/Api'
import { withRouter } from 'react-router-dom'
import BasicLayout,{ ModuleContent, ModuleHeader } from '../../layouts/basic/BasicLayout'
import { defaultFormLayout } from '../../configs/config'
import style from './manager.module.less'

class RoleEdit extends Component {
    formRef = React.createRef()

    state = {}

    componentDidMount() {}

    fetch(id) {}

    handleSubmit = values => {
        API.post(`/roles/createRole`, values).then(res => {
            message.success('保存成功')
            this.props.history.goBack()
        })
    }



    render() {
        return (
            <BasicLayout>
                <ModuleContent>
                    <ModuleHeader
                        title="角色添加"
                    />

                    <div className={style.form_content}>
                        <Form
                            {...defaultFormLayout.itemLayout}
                            ref={this.formRef}
                            onFinish={this.handleSubmit}
                        >
                            <Form.Item
                                label="角色ID"
                                name="id"
                                rules={[
                                    {
                                        required: true,
                                        message: '角色id必填'
                                    },
                                    // {

                                    //     pattern: '/^[a-zA-Z0-9._-]+$/',
                                    //     message: '角色id只能填写英文'
                                    // }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="角色名称"
                                name="title"
                                rules={[
                                    { required: true, pmessage: '角色名称必填' }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item {...defaultFormLayout.submitBtLayout}>
                                <Button type="primary" htmlType="submit">
                                    保存
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </ModuleContent>
            </BasicLayout>
        )
    }
}

export default withRouter(RoleEdit)
