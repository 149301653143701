import React, { useState } from "react";
import {
    Table,
    Row,
    Col,
    Input,
    Button,
    Divider,
    message,
    Popconfirm,
} from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import { Link } from "react-router-dom";
import DictSelect from "../../components/DictSelect";
import DateRangeSelect from "../../components/DateRangeSelect";
import useTable from "../../hooks/useTable";
import style from "./position.module.less";
import Util from "../../services/Util";
import Main from "../share/Main";
import DataSelect from "../../components/DataSelect.js";
import API from "../../services/Api";

import {CheckCircleTwoTone,CloseCircleTwoTone} from "@ant-design/icons";
import { Typography } from 'antd';

const { Text } = Typography;

const RuleList = () => {
    const [queryWord, setQueryWord] = useState("");
    const [category, setCategory] = useState(null);
    const [page, setPage] = useState(0);
    const [merchantId, setMerchantId] = useState(null);
    const [dateRange, setDateRange] = useState(Util.dateRange(7 * 365, "days"));
    const path = "/position/ruleEdit/";

    const columns = [
        {
            title: "名称",
            dataIndex: ["title"],
            width: 220,
            render: (text, record) => <Text strong>{record.title}</Text>
        },
        {
            title: "商户",
            dataIndex: ["merchant", "title"],
        },
        {
            title: "类型",
            dataIndex: ["category", "title"],
            render: (text, record) => <Text code>{record.category.title}</Text>
        },
        {
            title: "预付费",
            dataIndex: ["preFee"],
            render: (text, record) => <Text code>{(record.preFee / 100).toFixed(2)}</Text>,
        },

        {
            title: "可用",
            dataIndex: ["enabled"],
            render: (text, record) => (record.enabled ? (<CheckCircleTwoTone twoToneColor="#52c41a" />) : (<CloseCircleTwoTone twoToneColor="red"/>)),
        },
        {
            title: "创建时间",
            dataIndex: ["createTime"],
        },
        {
            title: "备注",
            dataIndex: "remark",
            render: (text, record) => (
                <div>{record.remark ? record.remark : "-"}</div>
            ),
        },
        {
            title: "操作",
            dataIndex: "id",
            fixed: 'right',
            width: 120,
            render: (text, record) => (
                <div>
                    <Link to={path + record.id}>编辑</Link>
                    {Main.isMerchant() ? (
                        <>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定操作吗?"
                                onConfirm={() => changeEnabled(record.id)}
                            >
                                {record.enabled ? <a>停用</a> : <a>启用</a>}
                            </Popconfirm>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            ),
        },
    ];

    const { tableProps, reloadTable } = useTable({
        url: "/rules/query",
        queryParams: {
            page,
            queryWord: queryWord,
            merchantId: merchantId ? merchantId.id : null,
            categoryId: category ? category.id : "",
            startDate: dateRange[0],
            endDate: dateRange[1],
        },
        onPageChange: (page) => {
            setPage(page);
        },
    });

    const onMerchantChange = (merchant) => {
        setMerchantId(merchant);
    };

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    };

    const onCategoryChange = (category) => {
        setCategory(category);
    };

    const changeEnabled = (id) => {
        API.post("/rules/changeEnabled", { id: id }).then((res) => {
            message.success("操作成功");
            reloadTable();
        });
    };

    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="150px">
                        {Main.isMerchant() ? (
                            <Button type="primary" style={{ marginRight: 8 }}>
                                <Link to="/position/ruleCreate">新增规则</Link>
                            </Button>
                        ) : (
                            ""
                        )}
                    </Col>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <DataSelect
                            allowClear
                            onChange={onMerchantChange}
                            dataUrl="/merchants/listAll"
                            placeholder="商户"
                            className={style.dict}
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onCategoryChange}
                            categoryKey="RULE_CATEGORY"
                            placeholder="类型"
                        />
                        <Input.Search
                            placeholder="搜索"
                            onSearch={(g) => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} scroll={{ x: 1500, y: 800 }}/>
            </ModuleContent>
        </BasicLayout>
    );
};

export default RuleList;
