import React, { useState } from "react";
import { NavLink, withRouter, Redirect, useHistory } from "react-router-dom";
import {
    DownOutlined,
    KeyOutlined,
    LogoutOutlined,
    UserOutlined,
} from "@ant-design/icons";

import { Menu, Button, Avatar, Dropdown, PageHeader, Space } from "antd";
import { Layout } from "antd";
import style from "./basic.module.less";
import Account from "../../services/Account";
import store from "store";
import menuData, { menuIcons } from "../../configs/menu";
import ModifyPassword from "./ModifyPassword";
import configs from "../../configs/config";

const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu;

const HeaderAvatar = () => {
    let history = useHistory();

    const [modifyPasswordVisible, setModifyPasswordVisible] = useState(false);
    const [accountTitle] =  useState(Account.info("accountTitle"));

    const showPasswordModel = (e) => {
        setModifyPasswordVisible(true);
    };

    const signOut = e => {
        Account.signOut();
        history.push("/account/signIn");
    }

    const onModifyPasswordClose = () => {
        setModifyPasswordVisible(false);
    }

    const userMenu = (
        <Menu>
            <Menu.Item className={style.configItem}>
                <a rel="noopener noreferrer" onClick={showPasswordModel}>
                    <KeyOutlined /> 修改密码
                </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item className={style.configItem}>
                <a rel="noopener noreferrer" onClick={signOut}>
                    <LogoutOutlined /> 退出系统
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={userMenu}>
                <span className={`${style.action} ${style.account}`}>
                    <Avatar icon={<UserOutlined />} className={style.avatar} size="small"/>
                    <span className={`${style.name} anticon`}>{accountTitle}</span>
                </span>
            </Dropdown>
            <ModifyPassword
                visible={modifyPasswordVisible}
                onClose={onModifyPasswordClose}
            />
        </>
    );
};

export default HeaderAvatar;
