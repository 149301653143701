import React, { useState, useEffect } from "react";
import { Select } from "antd";
import API from "../services/Api";

const { Option } = Select;

const DictSelect = ({
    value,
    onChange,
    categoryKey,
    selectFirst,
    ...restProps
}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        setLoading(true);
        API.post("/dicts/getDicts", {
            categoryKey: categoryKey
        })
            .then(res => {
                setLoading(false);
                setData(res.data);

                if (selectFirst === true) {
                    //默认选择数据集第一个作为项目
                    
                    if (onChange && res.data.length > 0) {
                        onChange(res.data[0]);
                    }
                }
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const handleChange = value => {
        if (onChange) {
            onChange(toMyComponentValue(value));
        }
    };

    const toAntComponentValue = v => {
        return v ? {
            key: v.id,
            label: v.title
        } : null;
    };

    const toMyComponentValue = v => {
        return v ? {
            id: v.key,
            title: v.label
        } : null;
    };

    const valueProp = value ? { value: toAntComponentValue(value) } : "";

    return (
        <Select
            // style={{ maxWidth: '240px' }}
            // size={"middle"}
            placeholder="请选择"
            {...restProps}
            loading={loading}
            onChange={handleChange}
            labelInValue
            {...valueProp}
        >
            {data.map(item => (
                <Option value={item.id} key={item.id}>
                    {item.title}
                </Option>
            ))}
        </Select>
    );
};

export default DictSelect;
