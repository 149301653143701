import React, { useDebugValue, useState } from "react";
import { Popconfirm, Form, Input,message,
    Divider,Button } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader,
} from "../../layouts/basic/BasicLayout";
import { useParams, useHistory } from "react-router-dom";
import useForm from "../../hooks/useForm";
import style from "./fee.module.less";
import SpinBox from "../../components/SpinBox";
import Main from "../share/Main";
import API from "../../services/Api";

const RefundDetail = () => {
    let history = useHistory();
    const [formData,setFormData] = useState(null)
    const onFormLoad = (form, data) => {
        setFormData(data)
        form.setFieldsValue({
            id: data.id,
            invoiceNumber: data.invoiceNumber,
            orderNumber: data.orderNumber,
            // platformRate: data.platformRate,
            // parentRate: data.parentRate,
            statusTitle: data.status.title,
            merchantNamne: data.merchant.title,
            fee: (data.fee / 100).toFixed(2) ,
            userName: data.user.userName,
            finishTime: data.finishTime,
        });
    };

    const { formProps, loading, submitBtLayout, editMode, id } = useForm({
        id: useParams().id,
        formLoadUrl: "/refunds/get",
        onFormLoad: onFormLoad,
    });


    const updateStatus = () => {
        API.post("/refunds/updateRefundStatus", { id: formData.id }).then((res) => {
            message.success("更新成功");
            document.location.reload();
        });
    };

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="退款记录详情" />

                <Form {...formProps} className={style.form_content}>
                    <SpinBox loading={loading}>
                        <Form.Item label="提现单号" name="invoiceNumber">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="订单号" name="orderNumber">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="状态" name="statusTitle">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="提现商户" name="merchantNamne">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="金额" name="fee">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="用户" name="userName">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="到账时间" name="finishTime">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item {...submitBtLayout}>                            

                            { (Main.isMerchant() && formData && formData.status && formData.status.id === 'REFUND_STATUS_FAILURE') ? (
                                <>
                                    <Divider type="vertical" />
                                    <Popconfirm
                                        placement="bottomRight"
                                        title="只有状态为“退款失败”才能更新? 该操作无法恢复"
                                        onConfirm={updateStatus}
                                        okText="更新状态"
                                        cancelText="取消"
                                    >
                                        <Button danger type="primary">
                                        更新状态
                                        </Button>
                                    </Popconfirm>
                                </>
                            ) : (
                                ""
                            )}                            
                        </Form.Item>
                    </SpinBox>
                </Form>
            </ModuleContent>
        </BasicLayout>
    );
};


export default RefundDetail;
