import React, { Component, useState } from "react";
import { Menu } from "antd";
import BasicLayout from "../../layouts/basic/BasicLayout";
import style from "./report.module.less";
import DeviceDayReport from "./DeviceDayReport";
import DeviceMonthReport from "./DeviceMonthReport";

const DeviceReport = () => {
    const [currentKey, setCurrentKey] = useState("day");

    const handleClick = (e) => {
        setCurrentKey(e.key);
    };

    const Report = () => {
        return currentKey == "day" ?  <DeviceDayReport/> : <DeviceMonthReport />;
    }

    return (
        <BasicLayout>
            <Menu
                onClick={handleClick}
                selectedKeys={[currentKey]}
                mode="horizontal"
                className={style.menu}
                style={{ marginBottom: 15}}
            >
                <Menu.Item key="day" className={style.item}>设备日报表</Menu.Item>
                <Menu.Item key="month" className={style.item}>设备月报表</Menu.Item>
            </Menu>
            <Report/>
        </BasicLayout>
    );
};

export default DeviceReport;
