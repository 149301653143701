import React, { useState } from "react";
import { Form, Input, Button, message, Switch } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader,
} from "../../layouts/basic/BasicLayout";
import { useParams, useHistory } from "react-router-dom";
import useForm from "../../hooks/useForm";
import style from "./user.module.less";
import SpinBox from "../../components/SpinBox";
import Main from "../share/Main";
import DictSelect from "../../components/DictSelect";
import API from "../../services/Api";
import DataSelect from "../../components/DataSelect.js";

const UserDetail = () => {
    let history = useHistory();
   
    const onFormLoad = (form, data) => {
        form.setFieldsValue({
            id: data.id,
            userName: data.userName,
            mobile: data.mobile,
            createTime: data.createTime,
            status: data.status,
            memo: data.memo
        });
    };
    const onBeforeSave = (form, values) => {
        if(values.status) {
            values.statusId = values.status.id;
            delete values.status;
        }
    };

    const { formProps, loading, submitBtLayout, editMode, id } = useForm({
        id: useParams().id,
        formLoadUrl: "/users/get",
        saveUrl: "/users/setupUserInfo",
        saveMessage: "保存成功!",
        onBeforeSave: onBeforeSave,
        onFormLoad: onFormLoad,
    });

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="用户详情" />

                <Form {...formProps} className={style.form_content}>
                    <SpinBox loading={loading}>
                        <Form.Item label="用户名" name="userName">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="手机号码" name="mobile">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="注册时间" name="createTime">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="备注" name="memo">
                            <Input ></Input>
                        </Form.Item>

                        <Form.Item {...submitBtLayout}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                style={{ marginRight: 8 }}
                            >
                                保存
                            </Button>
                        </Form.Item>
                    </SpinBox>
                </Form>
            </ModuleContent>
        </BasicLayout>
    );
};

export default UserDetail;
