import React, { useState } from "react";
import { NavLink, withRouter, Redirect, useHistory } from "react-router-dom";
import { BellOutlined } from "@ant-design/icons";

import { Menu, Badge, Avatar, Dropdown, PageHeader, Space, Tag } from "antd";
import { Layout } from "antd";
import style from "./basic.module.less";

const HeaderTitle = (props) => {
    let history = useHistory();

    return (
        <Space align="baseline">
            <span className={style.title}>{props.title}</span>
            {/* <Tag color="blue" className={style.version}>标准版</Tag> */}
        </Space>
    );
};

export default HeaderTitle;
