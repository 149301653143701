import React, { useState } from "react";
import { Table, Row, Col, Input, Button, Divider, Popconfirm, Tag } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import { Link, useParams } from "react-router-dom";
import DictSelect from "../../components/DictSelect";
import { DownloadOutlined } from '@ant-design/icons';
import DateRangeSelect from "../../components/DateRangeSelect";
import useTable from "../../hooks/useTable";
import style from "./device.module.less";
import Util from "../../services/Util";
import API from "../../services/Api";
import Main from "../share/Main";
import DataSelect from "../../components/DataSelect.js";
import { Typography } from 'antd';

const { Text } = Typography;

const DeviceList = () => {
    const [queryWord, setQueryWord] = useState("");
    const [page, setPage] = useState(0);
    const [merchantId, setMerchantId] = useState(null);
    const [dateRange, setDateRange] = useState(Util.dateRange(7 * 365, "days"));

    let statusId = useParams().status;
    const [status, setStatus] = useState(statusId ? { id: statusId } : "");

    const path = "/device/deviceEdit/";
    const columns = [
        {
            title: "名称/编码",
            dataIndex: ["title"],
            width: 180,
            render: (id, record) => (
                <>
                    <div><Text strong>{record.title}</Text></div>
                    <div><Text strong>{record.uniqueId}</Text>  </div>
                </>
            ),
        },
        {
            title: "商户",
            dataIndex: ["merchant", "title"],
        },
        {
            title: "状态",
            dataIndex: ["status", "title"],
            render: (id, record) => {
                let className = "default";
                if (record.status.id === 'DEVICE_STATUS_FREE') {
                    className = "success";
                } else if (record.status.id === 'DEVICE_STATUS_MAINTAIN') {
                    className = "error";
                } else if (record.status.id === 'DEVICE_STATUS_UNDISPATCH') {
                    className = "warning";
                } else if (record.status.id === 'DEVICE_STATUS_USE') {
                    className = "processing";
                }

                return <Tag color={className}>
                    {record.status.title}
                </Tag>
            },
        },
        {
            title: "上次/当前桩",
            dataIndex: ["slot"],
            render: (text, record) => (
                <div>
                    <Text code>{record.slot ? (record.slot.stub.title ? record.slot.stub.title : record.slot.stub.uniqueId) : ""}</Text>
                    <br />
                    <Text code>{record.slot ? record.slot.stub.uniqueId : ""}</Text>
                </div>
            ),
        },
        {
            title: "创建时间",
            dataIndex: ["createTime"],
        },
        {
            title: "操作",
            dataIndex: ["id"],
            fixed: 'right',
            width: 240,
            render: (text, record) => (
                <div>
                    <Link to={path + record.id}>编辑</Link>
                    {Main.isMerchant() ? (
                        <>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定要删除吗?"
                                onConfirm={() => deleteRow(record.id)}
                            >
                                <a>删除</a>
                            </Popconfirm>
                        </>
                    ) : (
                        ""
                    )}

                    {Main.isMerchant() && (record.status.id === "DEVICE_STATUS_USE" ||
                        record.status.id === "DEVICE_STATUS_FREE") ? (
                        <>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定置为维修吗?"
                                onConfirm={() => deviceMaintain(record.id)}
                            >
                                <a>维修</a>
                            </Popconfirm>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定将设备置为未投放吗?"
                                onConfirm={() => deviceUndispatch(record.id)}
                            >
                                <a>未投放</a>
                            </Popconfirm>
                        </>
                    ) : record.status.id === "DEVICE_STATUS_MAINTAIN" ? (
                        <>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定要维修完成吗?"
                                onConfirm={() => maintainFinished(record.id)}
                            >
                                <a>维修完成</a>
                            </Popconfirm>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            ),
        },
    ];

    const { tableProps, reloadTable } = useTable({
        url: "devices/query",
        queryParams: {
            page,
            queryWord: queryWord,
            statusId: status ? status.id : "",
            merchantId: merchantId ? merchantId.id : "",
            startDate: dateRange[0],
            endDate: dateRange[1],
        },
        onPageChange: (page) => {
            setPage(page);
        },
    });

    const onMerchantChange = (merchant) => {
        setMerchantId(merchant);
    };

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    };

    const onStatusChange = (status) => {
        setStatus(status);
    };

    const deleteRow = (id) => {
        API.post(`/devices/delete`, { id }).then((res) => {
            reloadTable();
        });
    };

    const deviceMaintain = (id) => {
        API.post(`/devices/deviceMaintain`, { id }).then((res) => {
            reloadTable();
        });
    };

    const maintainFinished = (id) => {
        API.post(`/devices/maintainFinished`, { id }).then((res) => {
            reloadTable();
        });
    };

    const deviceUndispatch = (id) => {
        API.post(`/devices/deviceUndispatch`, { id }).then((res) => {
            reloadTable();
        });
    };

    const downloadTemplate = e => {
        document.location.href =
            process.env.REACT_APP_API_HOST +
            `/devices/downloadTemplate`;
    }


    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="300px">
                        {Main.isMerchant() ? (
                            <>
                                <Button>
                                    <Link to="/device/deviceUpload">
                                        批量导入
                                    </Link>
                                </Button>{" "}
                                <Button type="dashed" icon={<DownloadOutlined />}>
                                    <a onClick={downloadTemplate}>
                                        {" "}模板下载
                                    </a>
                                </Button>
                            </>
                        ) : (
                            ""
                        )}
                    </Col>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <DataSelect
                            allowClear
                            onChange={onMerchantChange}
                            dataUrl="/merchants/listAll"
                            placeholder="商户"
                            className={style.dict}
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onStatusChange}
                            categoryKey="DEVICE_STATUS"
                            placeholder="状态"
                        />
                        <Input.Search
                            placeholder="搜索"
                            onSearch={(g) => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} scroll={{ x: 1500, y: 800 }} />
            </ModuleContent>
        </BasicLayout>
    );
};

export default DeviceList;
