import React, {useState, useEffect} from "react";
import {
    TeamOutlined,
    CodeSandboxSquareFilled,
    PayCircleFilled,
    CodepenSquareFilled,
    FileExclamationOutlined,
    FileProtectOutlined,
    ProfileOutlined,
    ShopOutlined,
    InboxOutlined,
} from "@ant-design/icons";
import {Row, Col, Spin} from "antd";
import styles from "./myWork.module.less";
import API from "../../services/Api";
import Main from '../share/Main'
import {Link} from "react-router-dom";

const Stats = (props) => {
    const [data, setData] = useState([]);

    const StatsItem = ({item}) => {
        const IconType = item["icon"];

        return (
            <div className={styles.statsItem}>
                <Link to={item.path}>
                    <div className={styles.iconBox} style={{backgroundColor: item.color}}>
                        <IconType className={styles.icon}/>
                    </div>

                    <p className={styles.title}>{item.title}</p>
                    <p className={styles.data}>{item.data}</p>
                </Link>
            </div>
        );
    };

    const loadingFinished = ()=>{
        if (props.loadingFinished){
            props.loadingFinished();
        }
    }
    const loadData = () => {
        API.post("/report/homePages/dataCount").then((res) => {
            const data = res.data;
            const d = [
                
                {
                    icon: ShopOutlined,
                    color: "#ee998a",
                    title: "全部商户",
                    data: data.merchant,
                    visible: Main.isAdmin(),
                    path: "/merchant/merchantList"
                },
                {
                    icon: TeamOutlined,
                    color: "#73d13d",
                    title: "全部用户",
                    data: data.user,
                    visible: Main.isAdmin(),
                    path: "/user/userList"
                },
                {
                    icon: InboxOutlined,
                    color: "#8ea37a",
                    title: "设备",
                    data: data.device,
                    visible: Main.isAdmin() || Main.isMerchant(),
                    path: "/device/deviceList"
                },
                {
                    icon: CodeSandboxSquareFilled,
                    color: "#57abff",
                    title: "空闲桩数量",
                    data: data.unUseSlotCount,
                    visible: Main.isMerchant(),
                    path: "/device/slotList/DEVICE_STATUS_FREE"
                },
                {
                    icon: CodeSandboxSquareFilled,
                    color: "#2a50f6",
                    title: "使用中桩数量",
                    data: data.useSlotCount,
                    visible: Main.isMerchant(),
                    path: "/device/slotList/DEVICE_STATUS_USE"
                },
                {
                    icon: CodepenSquareFilled,
                    color: "#57abff",
                    title: "空闲设备数量",
                    data: data.unUseDeviceCount,
                    visible: Main.isMerchant(),
                    path: "/device/deviceList/DEVICE_STATUS_FREE"
                },
                {
                    icon: CodepenSquareFilled,
                    color: "#2a50f6",
                    title: "使用中设备数量",
                    data: data.useDeviceCount,
                    visible: Main.isMerchant(),
                    path: "/device/deviceList/DEVICE_STATUS_USE"
                },
                {
                    icon: FileProtectOutlined,
                    color: "#2ecc71",
                    title: "今日已完成订单",
                    data: data.finishOrder,
                    visible: Main.isAdmin() || Main.isMerchant(),
                    path: "/order/orderList"
                },
                {
                    icon: ProfileOutlined,
                    color: "#f97b63",
                    title: "使用中订单",
                    data: data.processingOrder,
                    visible: Main.isAdmin() || Main.isMerchant(),
                    path: "/order/orderListByStatus/ORDER_STATUS_PAIED"
                },                
                {
                    icon: FileExclamationOutlined,
                    color: "#fe0000",
                    title: '逾期订单',
                    data: data.overdueOrder,
                    visible: Main.isMerchant(),
                    path: "/order/orderListOverdue/OVERDUE"
                },
                {
                    icon: PayCircleFilled,
                    color: "#faad14",
                    title: Main.isMerchant() ? '今日收入' : '今日全部商户收入',
                    data: data.merchantIncome ? (data.merchantIncome / 100).toFixed(2) : 0,
                    visible: Main.isAdmin() || Main.isMerchant(),
                    path: "/fee/withdrawalList"
                },
                
            ];

            setData(d);
            loadingFinished();
        }).catch(e => {
            loadingFinished();
        })
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
            <div className={styles.stats}>
                <h3 className={styles.title}>运营数据</h3>
                <Row gutter={16}>
                    {data.map((item, index) => (
                        item.visible ? <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={6} key={index}>
                            <StatsItem item={item} key={index}/>
                        </Col> : ""
                    ))}
                </Row>
            </div>
    );
};

export default Stats;
