import React, { useState } from "react";
import { Table, Row, Col, Input, Button, Divider, Popconfirm } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import { Link } from "react-router-dom";
import DataSelect from "../../components/DataSelect.js";
import DateRangeSelect from "../../components/DateRangeSelect";
import useTable from "../../hooks/useTable";
import style from "./device.module.less";
import Util from "../../services/Util";
import API from "../../services/Api";
import Main from "../share/Main";

const StubList = () => {
    const [queryWord, setQueryWord] = useState("");
    const [page, setPage] = useState(0);
    const [merchantId, setMerchantId] = useState(null);
    const [positionId, setPositionId] = useState(null);
    const [dateRange, setDateRange] = useState(Util.dateRange(7 * 365, "days"));
    const path = "/device/stubEdit/";
    const columns = [
        {
            title: "名称/编码",
            dataIndex: ["title"],
            render: (id, record) => (
                <div>
                    <span>{record.title}</span>
                    <br />
                    <span>{record.uniqueId}</span>
                </div>
            ),
        },
        {
            title: "商户",
            dataIndex: ["merchant", "title"],
        },
        {
            title: "位置",
            dataIndex: ["position", "title"],
        },
        {
            title: "类型",
            dataIndex: ["deviceCategory", "title"],
        },
        {
            title: "电量/信号",
            dataIndex: ["battery"],
            render: (id, record) => (
                <div>
                    <span>{record.battery + "/" + record.rssi}</span>
                </div>
            ),
        },
        {
            title: "上报时间",
            dataIndex: ["lastUpdateTime"],
        },
        {
            title: "操作",
            dataIndex: ["id"],
            render: (text, record) => (
                <div>
                    {Main.isMerchant() ? (
                        <>
                            <Link to={path + record.id}>编辑</Link>
                            <Divider type="vertical" />
                        </>
                    ) : (
                        ""
                    )}

                    <Link to={"/device/slotList/" + record.id}>关联槽</Link>

                    {Main.isAdmin() ? (
                        <>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定要删除吗?"
                                onConfirm={() => deleteRow(record.id)}
                            >
                                <a>删除</a>
                            </Popconfirm>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            ),
        },
    ];

    const { tableProps, reloadTable } = useTable({
        url: "stubs/query",
        queryParams: {
            page,
            queryWord: queryWord,
            merchantId: merchantId ? merchantId.id : "",
            positionId: positionId ? positionId.id : "",
            startDate: dateRange[0],
            endDate: dateRange[1],
        },
        onPageChange: (page) => {
            setPage(page);
        },
    });

    const onMerchantChange = (merchant) => {
        setMerchantId(merchant);
    };
    const onPositionChange = (position) => {
        setPositionId(position);
    };
    

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    };

    const deleteRow = (id) => {
        API.post(`/stubs/delete`, { id }).then((res) => {
            reloadTable();
        });
    };
    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="300px">
                        {Main.isMerchant() ? (
                            <Button>
                                <Link to="/device/stubUpload">批量导入</Link>
                            </Button>
                        ) : (
                            ""
                        )}
                    </Col>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />

                        <DataSelect
                            onChange={onMerchantChange}
                            dataUrl="/merchants/listAll"
                            placeholder="商户"
                            className={style.dict}
                        />
                        <DataSelect
                            onChange={onPositionChange}
                            dataUrl="/positions/listAll"
                            placeholder="场所"
                            className={style.dict}
                        />
                        <Input.Search
                            placeholder="搜索"
                            onSearch={(g) => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} />
            </ModuleContent>
        </BasicLayout>
    );
};

export default StubList;
