import React, { useState } from "react";
import { Input, Button, Form, InputNumber, message } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader,
} from "../../layouts/basic/BasicLayout";
import { useParams } from "react-router-dom";
import useForm from "../../hooks/useForm";
import style from "./device.module.less";
import SpinBox from "../../components/SpinBox";
import DictSelect from "../../components/DictSelect";
import DataSelect from "../../components/DataSelect";
import Main from "../share/Main";

const SlotEdit = () => {
    const onFormLoad = (form, data) => {
        form.setFieldsValue(data);
    };

    const onBeforeSave = (form, values) => {
        if (values.status) {
            values.statusId = values.status.id;
        }
        // if(values.statusId === 'DEVICE_STATUS_UNDISPATCH') {
        //     message.error("状态不可设置为未投放")
        //     return;
        // }
    };

    const { formProps, loading, editMode, submitBtLayout, id } = useForm({
        id: useParams().id,
        formLoadUrl: "/slots/get",
        createUrl: "/slots/save",
        updateUrl: "/slots/update",
        saveMessage: "保存成功!",
        onBeforeSave: onBeforeSave,
        onFormLoad: onFormLoad,
    });

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="编辑" />

                <Form {...formProps} className={style.form_content}>
                    <SpinBox loading={loading}>
                        <Form.Item
                            label="所在桩"
                            name="stub"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DataSelect
                                dataUrl="/stubs/listAll"
                                disabled={editMode}
                            />
                        </Form.Item>

                        <Form.Item
                            label="状态"
                            name="status"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DictSelect
                                categoryKey="DEVICE_STATUS"
                                disabled={editMode}
                            />
                        </Form.Item>

                        <Form.Item label="索引" name="idx">
                            <InputNumber disabled={editMode} />
                        </Form.Item>

                        {/* <Form.Item {...submitBtLayout}>
                            {Main.isMerchant() ? (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    style={{ marginRight: 8 }}
                                >
                                    保存
                                </Button>
                            ) : (
                                ""
                            )}
                        </Form.Item> */}
                    </SpinBox>
                </Form>
            </ModuleContent>
        </BasicLayout>
    );
};

export default SlotEdit;
