import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Input, Button, message, Form } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader
} from "../../layouts/basic/BasicLayout";
import DataSelect from "../../components/DataSelect.js";
import { useParams, useHistory } from 'react-router-dom';
import useForm from "../../hooks/useForm";
import style from "./manager.module.less";
import SpinBox from "../../components/SpinBox";
import CryptoService from '../../services/cryptoService'

const AdminEdit = () => {
    const [isAdmin, setIsAdmin] = useState(false);

    const onFormLoad = (form, data) => {
        if (data.adminRole.id === "ADMIN") {
            setIsAdmin(true);
        }
        form.setFieldsValue(data);
    };

    const onBeforeSave = (form, values) => {
        values.adminRoleId = values.adminRole.id;
        values.password = values.password;
    };

    const { formProps, loading, editMode, submitBtLayout, id } = useForm({
        id: useParams().id,
        formLoadUrl: "/admins/get",
        createUrl: "/admins/createAdmin",
        updateUrl: "/admins/updateAdmin",
        saveMessage: "人员保存成功!",
        onBeforeSave: onBeforeSave,
        onFormLoad: onFormLoad
    });

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="人员编辑" />

                <Form {...formProps} className={style.form_content}>
                    <SpinBox loading={loading}>
                        {editMode ? (
                            <Form.Item label="人员Id">{id}</Form.Item>
                        ) : (
                            ""
                        )}

                        <Form.Item
                            label="用户名"
                            name="userName"
                            rules={[{ required: true, message: "用户名必填" }]}
                        >
                            <Input readOnly={editMode} />
                        </Form.Item>
                        <Form.Item
                            label="手机号"
                            name="mobile"
                            rules={[{ required: true, message: "手机号必填" }]}
                        >
                            <Input />
                        </Form.Item>
                        {editMode ? (
                            ""
                        ) : (
                            <Form.Item
                                label="初始密码"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "初始密码必填"
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        )}

                        <Form.Item
                            label="角色"
                            name="adminRole"
                            rules={[{ required: true, message: "角色必填" }]}
                        >
                            <DataSelect dataUrl="/roles/listAll" disabled={isAdmin}/>
                        </Form.Item>
                        <Form.Item
                            label="姓名"
                            name="realName"
                            rules={[{ required: true, message: "姓名必填" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label="工号" name="code">
                            <Input />
                        </Form.Item>
                        <Form.Item {...submitBtLayout}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                保存
                            </Button>
                        </Form.Item>
                    </SpinBox>
                </Form>
            </ModuleContent>
        </BasicLayout>
    );
};

export default AdminEdit;
