import React, {useState} from "react";
import BasicLayout from "../../layouts/basic/BasicLayout";
import AuthRole from "../../components/AuthRole";
import Stats from "./Stats";
import {Spin} from "antd";

const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const loadingFinished = ()=>{
        setLoading(false);
    }

    return (
        <BasicLayout>
            <AuthRole authKey="REPORT">
                <Spin spinning={loading}>
                    <Stats loadingFinished={loadingFinished}/>
                </Spin>
            </AuthRole>
        </BasicLayout>
    );
}
export default Dashboard;
