import React, { Component } from "react";

import { Button, Drawer, Input, Form, message } from "antd";
import API from "../../services/Api";
import CryptoService from '../../services/cryptoService'

const ModifyPassword = (props)=>  {
    const [form] = Form.useForm();

    const onClose = () => {
        props.onClose();
    };

    const handleSubmit = values => {
        API.post("/admins/modifyPassword", {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword

        }).then(res => {
            message.success("密码修改成功!")
            onClose();
        });
    };

    const checkPass2 = (rule, value, callback) => {
        const password1 = form.getFieldValue("newPassword");
        if (value && value !== password1) {
            callback("两次输入密码不一致！");
        } else {
            callback();
        }
    };

    const pwdValidator = {
        pattern: /^[0-9A-Za-z_]{5,21}$/,
        message: "密码在5-16位之间, 只能由数字，英文和下划线组成"
    };
    return (
        <Drawer
            title="修改密码"
            width={400}
            destroyOnClose={true}
            onClose={onClose}
            visible={props.visible}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <Form
                onFinish={handleSubmit}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                form={form}
            >
                <Form.Item
                    label="原密码"
                    name="oldPassword"
                    rules={[{ required: true, message: "原密码不能为空!" }]}
                >
                    <Input type="password" placeholder="请输入原密码" />
                </Form.Item>
                <Form.Item
                    label="新密码"
                    name="newPassword"
                    rules={[
                        { required: true, message: "新密码不能为空" },
                        pwdValidator
                    ]}
                >
                    <Input type="password" placeholder="请输入原密码" />
                </Form.Item>
                <Form.Item
                    label="确认新密码"
                    name="newPassword2"
                    rules={[
                        { required: true, message: "新密码不能为空" },
                        pwdValidator,
                        { validator: checkPass2 }
                    ]}
                >
                    <Input type="password" placeholder="请输入原密码" />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                    >
                        保存
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
}

export default ModifyPassword;
