import CryptoJS from 'crypto-js'

const CryptoService = {
    key: '53614b706d734f385459524a43424464',
    iv: '704c6562595439376e6d316d62754239',
    salt: '0adfa5e5a18bedae440a9e8ae7c84708',
    decrypt: function(word) {
        let key = CryptoJS.enc.Hex.parse(this.key)
        let iv = CryptoJS.enc.Hex.parse(this.iv)

        let decrypted3 = CryptoJS.AES.decrypt(word, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            pad: CryptoJS.pad.Pkcs7
        })

        return decrypted3
    },

    encrypt: function(data) {
        let key = CryptoJS.enc.Hex.parse(this.key)
        let iv = CryptoJS.enc.Hex.parse(this.iv)

        let encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            pad: CryptoJS.pad.Pkcs7
        })
        return encrypted.toString() //base64
    },

    sign: function(token, timestamp, nonce) {
        let a = this.decrypt(nonce)
        let str = token + timestamp + ':' + a.toString(CryptoJS.enc.Utf8)
        let kk = CryptoJS.MD5(str).toString()
        return kk
    },

    encryptPwd: function(pwd) {
        var md5 = CryptoJS.MD5(
            CryptoJS.MD5(pwd) + this.salt
        ).toString()
        return md5
    }
}

export default CryptoService
