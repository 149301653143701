import React, { Component } from 'react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload, message, Modal } from 'antd';

import API from '../services/Api'

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng
}

class ImageWall extends Component {
    state = {
        action: API.defaults.baseURL + '/files/uploadImage',
        previewVisible: false,
        previewImage: '',
        loading: false,
        fileList: []
    }

    handleCancel = () => this.setState({ previewVisible: false })

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true
        })
    }

    handleChange = ({ fileList }) => {

        this.setState({ fileList })


        let imgList = []
        fileList.map(item => {
            if (item.response) {
                imgList.push({
                    uid: item.response.id,
                    url: item.response.url
                })
            } else {
                imgList.push({
                    uid: item.uid,
                    url: item.url
                })
            }
        })
        this.triggerChange(imgList)
    }

    triggerChange = changedValue => {
        
        const { onChange } = this.props
        if (onChange) {
            onChange(changedValue)
        }
    }

    render() {
        const { previewVisible, previewImage, action, fileList } = this.state;

        let uploadFilelist;
        if(fileList.length > 0){
            uploadFilelist = fileList;
        }else if(this.props.value){
            uploadFilelist = this.props.value;
        }else{
            uploadFilelist = [];
        }

        
        const uploadButton = (
            <div>
                {this.state.loading ? (
                    <LoadingOutlined />
                ) : (
                        <PlusOutlined />
                    )}
                <div className="ant-upload-text">上传图片</div>
            </div>
        )
        return (
            <div>
                <Upload
                    action={action}
                    listType="picture-card"
                    fileList={uploadFilelist}
                    onPreview={this.handlePreview}
                    beforeUpload={beforeUpload}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                >
                    {uploadFilelist.length >= 5 ? null : uploadButton}
                </Upload>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        )
    }
}

export default ImageWall
