import React, { Component } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, message } from "antd";

import API from "../services/Api";
import style from "./component.module.less";
import store from "store";

function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng;
}

class ImageUpload extends Component {
    state = {
        action: API.defaults.baseURL + "/files/uploadImage?ob-token=" + store.get("ob-token"),
        loading: false
    };

    handleChange = info => {
        if (info.file.status === "uploading") {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === "done") {

            this.setState({
                data: info.file.response,
                loading: false
            });

            this.triggerChange(info.file.response);
        }
    };

    triggerChange = changedValue => {
        const { onChange } = this.props;
        if (onChange) {
            onChange({
                ...this.props.value,
                ...changedValue
            });
        }
    };

    render() {
        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">上传图片</div>
            </div>
        );

        return (
            <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={this.state.action}
                beforeUpload={beforeUpload}
                onChange={this.handleChange}
            >
                {this.props.value ? (
                    <img src={this.props.value.url} style={{ width: "100%" }} />
                ) : (
                    uploadButton
                )}
            </Upload>
        );
    }
}

export default ImageUpload;
