import React, { useState, useEffect } from "react";
import { Row, Col, Space, Table, Button } from "antd";

import API from "../../services/Api";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import style from "./report.module.less";
import moment from "moment";
import Util from "../../services/Util";
import DayColumnChart from "./DayColumnChart";
import BlockTitle from "../../components/BlockTitle";
import DateRangeSelect from "../../components/DateRangeSelect";
import Main from "../share/Main";
import ExportJsonExcel from "js-export-excel";
import { Typography,Tag} from 'antd';

const { Text} = Typography;

const DeviceDayReport = () => {

    const [dateRange, setDateRange] = useState([moment().subtract(1, "month"),moment(new Date())]);
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);
 
    const renderContent = (id, record, idx, children) => {
        let obj = {
            children: children,
            props: {},
        }

        let rowSpans = reportData.filter((item) => (
            item.day == record.day
        )).length;

        obj.props.rowSpan = rowSpans;

        if (idx > 0 && (reportData[idx - 1].day == reportData[idx].day)) {
            obj.props.rowSpan = 0;
        }

        return obj;
    }

    const columns = [
        {
            title: "日期",
            dataIndex: "day",
            render: (id, record, idx) => {
                let content = 
                <>
                    <Text strong>{record.day}</Text>                                                      
                </>                            
                return renderContent(id, record, idx, content);
            }
        },
        {
            title: "商户",
            dataIndex: "merchantTitle",
            render: (text, record) => <Text code>{record.merchantTitle}</Text>,
        },
        {
            title: "新设备数",
            dataIndex: "deviceNum",
            render: (text, record) => <Tag color="#108ee9" >{record.deviceNum}</Tag>,
        },
        {
            title: "总设备数",
            dataIndex: "deviceTotalNum",
            render: (text, record) => <Tag color="#108ee9" >{record.deviceTotalNum}</Tag>,
        },
        {
            title: "新桩数",
            dataIndex: "stubNum",
            render: (text, record) => <Tag color="#108ee9" >{record.stubNum}</Tag>,
        },
        {
            title: "总桩数",
            dataIndex: "stubTotalNum",
            render: (text, record) => <Tag color="#108ee9" >{record.stubTotalNum}</Tag>,
        },
    ];

    const fetch = () => {
        const values = {
            startDate: moment(dateRange[0]).format("YYYY-MM-DD 00:00:00"),
            endDate: moment(dateRange[1]).format("YYYY-MM-DD 23:59:59"),
        };

        API.post("/report/deviceReports/query", values).then((res) => {
            setReportData(res.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetch();
    }, [dateRange]);

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    };

    //导出设备日报表
    const downloadExcel = () => {
        var option={};
        let dataTable = [];
        if (reportData) {
            for (let i in reportData) {
                let obj = {
                '日期': reportData[i].day,
                '商户': reportData[i].merchantTitle,
                '新设备数': reportData[i].deviceNum,
                '总设备数': reportData[i].deviceTotalNum,
                '新桩数': reportData[i].stubNum,
                '总桩数': reportData[i].stubTotalNum,
                }
                dataTable.push(obj);
            }
        }
        option.fileName = '设备日报表-'+ dataTable[0].日期.slice(0, 7); // 文件名
        option.datas=[
            {
            sheetData:dataTable, // 数据源
            sheetName:'设备日报表', // excel中表名
            sheetFilter:['日期','商户','新设备数','总设备数', '新桩数', '总桩数'],
            sheetHeader:['日期','商户','新设备数','总设备数', '新桩数', '总桩数'], // 表格头部
            }
        ];

        var toExcel = new ExportJsonExcel(option); 
        toExcel.saveExcel();
    };

    return (
        <>
            <ModuleTool>
                <Row>
                    <Col flex="1">
                        <span className={style.report_title}>设备日报表</span>
                    </Col>
    
                    <Col flex="600px" className={style.query_area}>
                        <Space>
                            <span>设备日期区间</span>
                            <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        </Space>
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                {Main.isMerchant() && 
                    <DayColumnChart
                    title="总设备数趋势图"
                    data={reportData}
                    yField="deviceTotalNum"
                    yAlias="总设备数"
                    color="#2ecc71"
                    type="day"
                />
                }
                

                {
                    Main.isMerchant() && 
                    <DayColumnChart
                    title="新桩数趋势图"
                    data={reportData}
                    yField="stubNum"
                    yAlias="新桩数"
                    color="#03a9f4"
                    type="day"
                />
                }
                
                {
                    Main.isMerchant() && <DayColumnChart
                    title="总桩数趋势图"
                    data={reportData}
                    yField="stubTotalNum"
                    yAlias="总桩数"
                    color="#2ecc71"
                    type="day"
                />
                }
               
                
    
               <BlockTitle>
                    设备报表
                    <Button type="primary" style={{float:'right', marginRight:'30px'}} onClick={downloadExcel}>导出</Button>
                    </BlockTitle>

                <Table
                    dataSource={reportData}
                    size="default"
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                    loading={loading}
                />
            </ModuleContent>
        </>
    );
};



export default DeviceDayReport;
