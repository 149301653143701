import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { BrowserRouter as Router } from "react-router-dom";
import { validateMessages } from './configs/config'
import 'moment/locale/zh-cn';



ReactDOM.render(
  <Router basename={process.env.REACT_APP_BASE_NAME}>
    <ConfigProvider locale={zhCN} autoInsertSpaceInButton={false} form={{validateMessages}}>
      <App />
    </ConfigProvider>
  </Router>,
  document.getElementById('root')
);

serviceWorker.unregister();
