import React, { Component } from "react";
import { Spin } from "antd";
import style from "./component.module.less";

const SpinBox = props => {
    return (
        <Spin className={style.spin_box} spinning={props.loading} delay={500}>
            {  props.children}
        </Spin>
        
    );
};

export default SpinBox;
