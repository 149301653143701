import React, { useState } from "react";
import {
    Table,
} from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleTool
} from "../../layouts/basic/BasicLayout";
import { Link } from 'react-router-dom'
import useTable from "../../hooks/useTable";


const ConfigList = () => {

    const columns = [
        {
            title: "配置项",
            dataIndex: "title",
        },
        {
            title: "当前值",
            dataIndex: "value",
        },
        {
            title: "备注",
            dataIndex: "memo",
        },
        {
            title: "操作",
            dataIndex: "id",
            render: (text, record) => (
                <div>
                    <Link to={"/manager/configEdit/" + record.id}>编辑</Link>
                </div>
            ),
        },
    ];

    const { tableProps, reloadTable } = useTable({
        url: "/configs/getConfigsByGroup",
        queryParams: {
            groupName: 'GENERAL',
        },
        paging: false,
    });

    return (
        <BasicLayout>
             <ModuleTool></ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} />
            </ModuleContent>
        </BasicLayout>
    );
};

export default ConfigList;
