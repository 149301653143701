//管理系统配置文件

const configs = {
    items: [
        {
            key: "obiot",
            systemConfigTabs: ["SHOPX", "APP_UPDATE"], //系统服务器参数要显示的分组
            loginTitle: "国尚达共享陪护床管理平台",
            loginFooterTitle: "深圳腾悦科技有限公司",
            headerTitle: "深圳腾悦科技有限公司",
            footerTitle: "深圳腾悦科技有限公司",
            copyright: "2022 深圳腾悦科技有限公司",
            icp: "",
            needIcon: false,
        },
        {
            key: "blgx",
            systemConfigTabs: ["SHOPX", "APP_UPDATE"], //系统服务器参数要显示的分组
            loginTitle: "芋享物联共享管理平台",
            loginFooterTitle: "深圳市博领智慧科技有限公司",
            headerTitle: "芋享物联共享管理平台",
            footerTitle: "芋享物联共享",
            copyright: "2020 深圳市博领智慧科技有限公司",
            icp: "",
            needIcon: true,
        },
    ],
    current: function () {
        const index = process.env.REACT_APP_CONFIGS_INDEX;
        return this.items[index];
    },
};

export default configs;

export const defaultFormLayout = {
    itemLayout: {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
            md: { span: 4 },
            xl: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
            md: { span: 10 },
            xl: { span: 6 },
        },
    },
    submitBtLayout: {
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12, offset: 5 },
            md: { span: 12, offset: 4 },
            xl: { span: 12, offset: 3 },
        },
    },
    contentsLayout: {
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 19 },
            md: { span: 20 },
            xl: { span: 18 },
        },
    },
    skuLayout: {
        wrapperCol: {
            xs: { span: 24 },
            md: { span: 10 },
            xl: { span: 8 },
        },
    },
    mapLayout: {
        wrapperCol: {
            xs: { span: 24 },
            md: { span: 19, push: 4 },
            xl: { span: 20, push: 3 },
        },
    },
};

export const validateMessages = {
    // required: "${name} 不能为空", 这里目录只能显示英文，以后更新
    required: "该项不能为空",
    // ...
};

export const defaultColumnLayout = {
    col2: {
        xs: { span: 12 },
        sm: { span: 12 },
        md: { span: 10 },
        xl: { span: 10 },
        xxl: { span: 4 },
    },
    col3: {
        xs: { span: 8 },
        sm: { span: 8 },
        md: { span: 8 },
        xl: { span: 6 },
        xxl: { span: 5 },
    },
};
