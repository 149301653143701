
import store from 'store';
import jwt from 'jsonwebtoken';

const Account = {
    tokenName: "ob-token",
    nonceName: "nonce",
    signIn: function(token, nonce){
        store.set(this.tokenName, token);
        // store.set(this.nonceName, nonce);
    },
    signOut: function(){
        store.remove(this.tokenName);
        store.remove(this.nonceName);
    },

    logined: function(){
        return store.get(this.tokenName);
    },
    info(p){
        const token = store.get(this.tokenName);
        if(token){
            let decoded = jwt.decode(token);
            return decoded[p];
        }
    }
    
}

export default Account;