import {
    DashboardOutlined,
    PieChartOutlined,
    PictureOutlined,
    SettingOutlined,
    PayCircleOutlined,
    ShoppingCartOutlined,
    TeamOutlined,
    ShopOutlined,
    InboxOutlined,
    ProfileOutlined,
    EnvironmentOutlined,
    CommentOutlined,
    ToolOutlined,
} from "@ant-design/icons";

export const menuIcons = {
    dashboard: DashboardOutlined,
    team: TeamOutlined,
    bed: InboxOutlined,
    rule: ProfileOutlined,
    merchant: ShopOutlined,
    position: EnvironmentOutlined,
    message: CommentOutlined,
    order: ShoppingCartOutlined,
    content: PictureOutlined,
    pay: PayCircleOutlined,
    report: PieChartOutlined,
    maintain: ToolOutlined,
    setting: SettingOutlined,
};

const menus = [
    {
        title: "我的工作",
        icon: "dashboard",
        subMenus: [{ title: "工作台", url: "/myWork/dashboard" }],
    },
    {
        title: "商户管理",
        icon: "merchant",
        subMenus: [
            {
                title: "商户列表",
                url: "/merchant/merchantList",
                authKey: "MERCHANT",
            },
        ],
    },
    {
        title: "设备管理",
        icon: "bed",
        subMenus: [
            { title: "桩列表", url: "/device/slotList", authKey: "DEVICE" },
            { title: "设备列表", url: "/device/deviceList", authKey: "DEVICE" },
        ],
    },
    {
        title: "场所管理",
        icon: "position",
        subMenus: [
            {
                title: "投放场所列表",
                url: "/position/positionList",
                authKey: "POSITION",
            },
        ],
    },
    {
        title: "规则管理",
        icon: "rule",
        subMenus: [
            {
                title: "规则列表",
                url: "/position/ruleList",
                authKey: "POSITION",
            },
        ],
    },
    {
        title: "订单管理",
        icon: "order",
        subMenus: [
            { title: "订单列表", url: "/order/orderList", authKey: "ORDER" },
        ],
    },
    {
        title: "支付管理",
        icon: "pay",
        subMenus: [
            { title: "支付清单", url: "/fee/payList", authKey: "PAY" },
            { title: "交易流水", url: "/fee/moneyList", authKey: "PAY" },
            { title: "提现记录", url: "/fee/withdrawalList", authKey: "PAY" },
            { title: "退款记录", url: "/fee/refundList", authKey: "PAY" },
        ],
    },
    // {
    //     title: "消息通知",
    //     icon: "message",
    //     subMenus: [
    //         { title: "通知列表", url: "/sms/smsList", authKey: "SMS" },
    //     ]
    // },
    {
        title: "内容管理",
        icon: "content",
        subMenus: [
            { title: "内容", url: "/cms/bulletinList", authKey: "CMS" },
            // { title: "常见问题", url: "/cms/questionList", authKey: "CMS" },
        ],
    },
    {
        title: "用户中心",
        icon: "team",
        subMenus: [
            { title: "用户列表", url: "/user/userList", authKey: "USER" },
        ],
    },
    {
        title: "故障上报",
        icon: "maintain",
        subMenus: [
            {
                title: "故障列表",
                url: "/maintain/maintainList",
                authKey: "MAINTAIN",
            },
        ],
    },
    {
        title: "报表",
        icon: "report",
        subMenus: [
            
           
            {
                title: "用户报表",
                url: "/report/userReport",
                authKey: "ADMIN",
            },
            {
                title: "设备报表",
                url: "/report/deviceReport",
                authKey: "REPORT",
            },
            {
                title: "桩状态报表",
                url: "/report/stubInfoReport",
                authKey: "REPORT",
            },
            {
                title: "订单报表",
                url: "/report/orderReport",
                authKey: "REPORT",
            },
            {
                title: "订单明细",
                url: "/report/orderPayDetail",
                authKey: "ADMIN",
            },
            {
                title: "商户收入报表",
                url: "/report/merchantReport",
                authKey: "REPORT",
            },
            {
                title: "提现报表",
                url: "/report/withdrawReport",
                authKey: "ADMIN",
            },
            {
                title: "退款报表",
                url: "/report/refundReport",
                authKey: "ADMIN",
            },
            {
                title: "报表生成",
                url: "/report/generateReport",
                authKey: "REPORT",
            },
        ],
    },
    {
        title: "系统管理",
        icon: "setting",
        subMenus: [
            { title: "人员管理", url: "/manager/adminList", authKey: "RBAC" },
            { title: "角色权限", url: "/manager/roleList", authKey: "RBAC" },
            { title: "系统参数", url: "/manager/configList", authKey: "RBAC" },
        ],
    },
];

export default menus;
