
import React from 'react';
import { Result, Button } from 'antd';
import AccountLayout from '../../layouts/account/AccountLayout';
import { withRouter, Link } from 'react-router-dom';


const NotAuthed =() => {
    return (
        <AccountLayout>
            <Result
                status="403"
                title="403"
                // subTitle="对不起，您无权访问该页面或认证时间已经超时, 请重新登录"
                subTitle={<>
                    <p>对不起，请重新登录。原因可能如下：</p>
                    <p>1、您无权访问该页面</p>
                    {/* <p>2、认证时间已经超时</p> */}
                    {/* <p>3、共用同个账号造成的</p> */}
                </>}
                extra={<>
                    <Button type="primary" ><Link to="/account/signIn">返回登录</Link></Button>
                </>}
            />
        </AccountLayout>
    );
}

export default withRouter(NotAuthed);
