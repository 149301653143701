import React from "react";
import {Input, Button, Form } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader
} from "../../layouts/basic/BasicLayout";
import { useParams } from 'react-router-dom';
import useForm from "../../hooks/useForm";
import style from "./manager.module.less";
import SpinBox from "../../components/SpinBox";

const ConfigEdit = () => {

    const { formProps, loading, editMode, submitBtLayout, id } = useForm({
        id: useParams().id,
        formLoadUrl: "/configs/get",
        updateUrl: "/configs/saveConfig",
        saveMessage: "保存成功!",
    });

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="参数编辑" />

                <Form {...formProps} className={style.form_content}>
                    <SpinBox loading={loading}>
                        
                        <Form.Item
                            label="名称"
                            name="title"
                            rules={[{ required: true, message: "用户名必填" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="当前值"
                            name="value"
                            rules={[{ required: true, message: "手机号必填" }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="备注"
                            name="memo"
                            rules={[{ required: true, message: "姓名必填" }]}
                        >
                            <Input />
                        </Form.Item>
                       
                        <Form.Item {...submitBtLayout}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                保存
                            </Button>
                        </Form.Item>
                    </SpinBox>
                </Form>
            </ModuleContent>
        </BasicLayout>
    );
};

export default ConfigEdit;
