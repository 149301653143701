import Account from '../../services/Account'


const Main = {
    isAdmin(){
        return Account.info('accountType') === 'ADMIN';
    },
    isMerchant(){
        return Account.info('accountType') === 'MERCHANT';
    },
    isSender(){
        return Account.info('accountType') === 'MANAGER';
    },
    isAfterSale(){
        return Account.info('accountType') === 'MAINTAIN';
    }
    
}

export default Main;