import React, { useState, useCallback, useEffect }  from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { Form, message, Button, Select } from 'antd';
import { defaultFormLayout } from '../configs/config'
import API from "../services/Api";



function useForm({
    formLoadUrl, //表单加载数据url，
    id, //表单id, 用地更新表单
    saveUrl, //如果设置了saveUrl, 以saveUrl没准。createUrl和updateUrl就不需要了。
    createUrl,
    updateUrl,
    saveMessage = "保存成功",
    pathAfterSave, //default 不填则goBack() 返回上级页面，设置为false则不跳转页面
    onBeforeSave, //提交到服务器前的事件，在这里要添加一些自定义的数据 
    onFormInit, //新建项目时的事件，可以在这做一些创建操作的初始化
    onFormLoad, //完成从服务器加载数据的操作，在这里在一些自定义的数据填充
    onSaveFinish //保存数据成功后的操作，和pathAfterSave=false结合使用，如关闭Modal
}){

    const editMode = id ? true : false;
    
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data,setData] = useState({})
    
    let history = useHistory();

    const onFinish = useCallback(values => {

        if(onBeforeSave){
            onBeforeSave(form, values);
        }

        if(editMode){
            values.id = id;
        }

        setLoading(true);

        let params = {};
        if (editMode){
            params = {...data,...values};
        }else{
            params = {...values};
        }

        API.post(saveUrl || ( editMode ? updateUrl : createUrl), params).then(res => {
            message.success(saveMessage);
            setLoading(false);

            if(pathAfterSave === false){
                //不跳转
                if(onSaveFinish){
                    onSaveFinish(form, values);
                }
            }else{
                if(pathAfterSave){
                    history.replace(pathAfterSave)
                }else{
                    history.goBack();
                }
            }
            
        }).catch(res => {
            setLoading(false);
        })
    });

    fetch = () => {
        setLoading(true);
        API.post(formLoadUrl, {
            id: id
        }).then(res => {
            const data = res.data
            setData(res.data);
            if(onFormLoad){
                onFormLoad(form, data);
            }else{
                form.setFieldsValue(data);
            }

            setLoading(false);
        }).catch(res => {
            setLoading(false);
        })
    }


    useEffect(() => {
        if (editMode) {
            fetch();
        }else{
            if(onFormInit){
                onFormInit(form);
            }
        }
        
    }, []);


    return {
        
        submitBtLayout: defaultFormLayout.submitBtLayout,
        editMode,
        loading,
        setLoading,
        id,
        formProps : {
            ...defaultFormLayout.itemLayout,
            onFinish,
            form
        }
    }

}

export default useForm;
