import React, { useState } from "react";
import { Table, Row, Col, Input, Popconfirm } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import useTable from "../../hooks/useTable";
import style from "./position.module.less";
import API from "../../services/Api";
import Main from "../share/Main";

const PositionBoxList = ({ id, canDelete = true }) => {
    const [queryWord, setQueryWord] = useState("");
    const [page, setPage] = useState(0);

    const columns = [
        {
            title: "名称/编码",
            dataIndex: ["title"],
            render: (id, record) => (
                <div>
                    <span>{record.title}</span>
                    <br />
                    <span>{record.uniqueId}</span>
                </div>
            ),
        },
        {
            title: "类型",
            dataIndex: ["deviceCategory", "title"],
        },
        {
            title: "电量/信号",
            dataIndex: ["battery"],
            render: (id, record) => (
                <div>
                    <span>
                        {record.battery + "/" + record.rssi}
                    </span>
                </div>
            ),
        },
        {
            title: "创建时间",
            dataIndex: ["createTime"],
        },
        {
            title: "最后上报时间",
            dataIndex: ["lastUpdateTime"],
        },
    ];

    if (canDelete) {
        columns.push(
            {
                title: "操作",
                dataIndex: ["id"],
                render: (text, record) => (
                    <div>
                        {Main.isMerchant() ? (<Popconfirm
                            title="确定移除吗?"
                            onConfirm={() => remove(record.id)}
                        >
                            <a>从场所移除</a>
                        </Popconfirm>) : "-"}
                        
                    </div>
                ),
            },
        )
    }

    const remove = (id) => {
        API.post(`/positions/removeStubs`, { id }).then((res) => {
            reloadTable();
        });
    };

    const { tableProps, reloadTable } = useTable({
        url: "stubs/queryByPosition",
        queryParams: {
            page,
            queryWord: queryWord,
            pid: id,
        },
        onPageChange: (page) => {
            setPage(page);
        },
    });

    return (
        <>
            <ModuleTool>
                <Row>
                    <Col flex="300px">投放桩列表</Col>
                    <Col flex="auto" className={style.query_area}>
                        <Input.Search
                            placeholder="搜索"
                            onSearch={(g) => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} />
            </ModuleContent>
        </>
    );
};

export default PositionBoxList;
