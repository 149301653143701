import React, { useState, useEffect } from "react";
import { Row, Col, Space, Table, Button, DatePicker } from "antd";

import API from "../../services/Api";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import style from "./report.module.less";
import moment from "moment";
import ExportJsonExcel from "js-export-excel";
import { Typography, Tag } from 'antd';
import DataSelect from "../../components/DataSelect.js";
import { DownloadOutlined } from "@ant-design/icons";
import StubInfoHistogramChart from "./StubInfoHistogramChart"
import DictSelect from "../../components/DictSelect";
import { set } from "store";
const { Text } = Typography;


const StubInfoReport = () => {

    const [positionId, setPositionId] = useState("");
    const [merchantId, setMerchantId] = useState("");
    const [deviceCategoryId, setDeviceCategoryId] = useState("");
    const [batteryStatusId, setBatteryStatusId] = useState("");
    const [rssiStatusId, setRssiStatusId] = useState("");

    const params = { merchantId,positionId,deviceCategoryId,batteryStatusId,rssiStatusId };

    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);

    const dateFormat = 'YYYY/MM/DD'

    let queryDeps = params
        ? Object.keys(params).map((key) => params[key])
        : [];
    
    const columns = [
        {
            title: "商户",
            dataIndex: "merchantTitle",
            render: (text, record) => <Text strong>{record.merchantTitle}</Text>,
        },
        {
            title: "场所",
            dataIndex: "positionTitle",
            render: (text, record) => <Text >{record.positionTitle ? record.positionTitle  : "-"}</Text>,
        },
        {
            title: "编号",
            dataIndex: "uniqueId",
            render: (text, record) => <Text >{record.uniqueId}</Text>,
        },
        {
            title: "电量",
            dataIndex: "battery",
            render: (text, record) => <Text >{record.battery}</Text>,
        },
        {
            title: "信号",
            dataIndex: "rssi",
            render: (text, record) => <Text >{record.rssi}</Text>,
        },


    ];

    const fetch = () => {
        API.post("/stubs/stubInfoReport", params).then((res) => {
           
            setReportData(res.data.results);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetch();
    }, queryDeps);


    const changePosition = (position) => {
        setPositionId(position ? position.id : "");
    };

    const onMerchantChange = (merchant) => {
        setMerchantId(merchant ? merchant.id : "");       
    };

    const onCategoryChange = (category) => {
        setDeviceCategoryId(category ? category.id :"")
    };
    
    const onBatteryChange = (battery) => {
        setBatteryStatusId(battery ? battery.id : "")                 
    };

    const onRSSIChange = (rssi) => {
          setRssiStatusId(rssi ? rssi.id : "")  
    };
    const downloadExcel = () => {
        var option = {};
        let dataTable = [];
        if (reportData) {
            for (let i in reportData) {
                let obj = {                 
                    '商户': reportData[i].merchantTitle,
                    '场所': reportData[i].positionTitle,
                    '编号': reportData[i].uniqueId,
                    '电量': reportData[i].battery,
                    '信号': reportData[i].rssi,
                
                }
                dataTable.push(obj);
            }
        }
        option.fileName = '桩状态报表'; // 文件名
        option.datas = [
            {
                sheetData: dataTable, // 数据源
                sheetName: '提现报表', // excel中表名
                sheetFilter: ['商户', '场所', '编号', '电量','信号'],
                sheetHeader: ['商户', '场所', '编号', '电量','信号'], // 表格头部
            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    };


    const batteryColumnStyleFormatter = (region)=>{
        if(region[1] <=30){
            return {
                fill: '#f50',            
              }
        }else if(region[1] >30 && region[1] <=80){
            return {
                fill: '#2db7f5',            
              }
        }else{
            return {
                fill:"#87d068"
            }
        }
    }

    const batteryTooltipFormatter = (datum, count) => {
        return { name: "" + datum[0] + "<=电量<" + datum[1] + "", value: count };
    }

    const rssiColumnStyleFormatter = (region)=>{
        if(region[1] <=10){
            return {
                fill: '#f50',            
              }
        }else if(region[1] >10 && region[1] <=20){
            return {
                fill: '#2db7f5',            
              }
        }else{
            return {
                fill:"#87d068"
            }
        }
    }

    const rssiTooltipFormatter = (datum, count) => {
        return { name: "" + datum[0] + "<=信号<" + datum[1] + "", value: count };
    }

    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="1">
                        <Button type="dashed" style={{ marginRight: '20px' }} icon={<DownloadOutlined />} onClick={downloadExcel}>导出</Button>

                    </Col>
                    <Col flex="auto" className={style.query_area}>
                        <Space>

                        <DataSelect
                            allowClear
                            onChange={onMerchantChange}
                            dataUrl="/merchants/listAll"
                            placeholder="商户"
                            className={style.dict}
                            style={{width:"100px"}}   
                        />
                        <DataSelect
                            allowClear                           
                            onChange={changePosition}
                            parameter={merchantId ? merchantId : ""}
                            dataUrl="/positions/validHospitalsByMId"
                            placeholder="场所"
                            className={style.dict}
                            style={{width:"100px"}}   
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onCategoryChange}
                            categoryKey="DEVICE_CATEGORY"
                            placeholder="桩类型"
                            style={{width:"100px"}}   
                        />
                         <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onBatteryChange}
                            categoryKey="BATTERY_STATUS"
                            placeholder="电量"
                            style={{width:"100px"}}   
                        />
                         <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onRSSIChange}
                            categoryKey="RSSI_STATUS"
                            placeholder="信号"
                            style={{width:"100px"}}   
                        />
                        </Space>
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
            <StubInfoHistogramChart
                    title="桩电量分布图"
                    data={reportData}   
                    dataKey="battery"       
                    binWidth={10}
                    xAxisText={"电量区间"} 
                    yAxisText={"数量"}
                    tooltipFormatter={batteryTooltipFormatter}    
                    columnStyleFormatter={batteryColumnStyleFormatter}         
                />
                <StubInfoHistogramChart
                    title="桩信号分布图"
                    data={reportData}   
                    dataKey="rssi"       
                    binWidth={10}
                    xAxisText={"信号区间"} 
                    yAxisText={"数量"}
                    tooltipFormatter={rssiTooltipFormatter}    
                    columnStyleFormatter={rssiColumnStyleFormatter}         
                />
                <Table
                    dataSource={reportData}
                    size="default"
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                    loading={loading}
                />
            </ModuleContent>
        </BasicLayout>
    );
};



export default StubInfoReport;
