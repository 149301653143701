import React, { Component } from 'react'
import { Radio } from 'antd'

class DictPublish extends Component {
  state = {
    dictList: [
      { id: '', title: '全部' },
      { id: true, title: '已发布' },
      { id: false, title: '未发布' },
    ]
  }

  changeStatus = e => {
    const isPublish = e.target.value
    this.props.changeStatus(isPublish)
  }

  render() {
    const { dictList } = this.state
    return (
      <Radio.Group onChange={this.changeStatus} defaultValue="">
        {dictList.map((item, index) => (
          <Radio.Button key={index} value={item.id}>
            {item.title}
          </Radio.Button>
        ))}
      </Radio.Group>
    )
  }
}

export default DictPublish
