import React, { useState } from "react";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import { Row, Col, Calendar, Button, Menu, message, Alert } from "antd";
import style from "./report.module.less";
import moment from "moment";
import API from "../../services/Api";
import { defaultColumnLayout } from "../../configs/config";
import Account from "../../services/Account";

const GenerateReport = () => {
    const [url, setUrl] = useState("/report/userReports/generateUserReport");
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(
        moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    );
    
    const reportList = [
        {
            title: "用户报表",
            url: "/report/userReports/generateUserReport",
        },
        {
            title: "设备报表",
            url: "/report/deviceReports/generateDeviceReport",
        },
        {
            title: "订单报表",
            url: "/report/orderReports/generateOrderReport",
        },
        {
            title: "商户收入报表",
            url: "/report/merchantReports/generateMerchantReport",
        },
    ];

    const onPanelChange = (value, mode) => {
        const newDay = moment(value._d).format("YYYY-MM-DD HH:mm:ss");
        setDate(newDay);
    };

    const onSubmit = () => {
        setLoading(true);
        API.post(url, { date }).then((res) => {
            message.success("生成报表成功");
            setLoading(false);
        });
    };

    return (
        <BasicLayout>
            <ModuleContent>
                <Row>
                    <Col {...defaultColumnLayout.col2} className={style.reportBlock}>
                        <div className={style.headerTitle}>报表类型</div>
                        <div className="m-t">
                            <Menu
                                theme="light"
                                mode="inline"
                                style={{
                                    borderRight: "none",
                                    borderLeft: "none",
                                }}
                                defaultSelectedKeys={["0"]}
                            >
                                {reportList.map((item, index) => (
                                    <Menu.Item
                                        key={index}
                                        onClick={() => setUrl(item.url)}
                                    >
                                        {item.title}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        </div>
                        <div className={style.reportFooter}>
                            
                            <Button
                                type="primary"
                                onClick={onSubmit}
                                loading={loading}
                                style={{ marginTop: "6px" }}
                            >
                                生成报表
                            </Button>
                            
                        </div>
                    </Col>
                    <Col flex="300px">
                        <Calendar
                            fullscreen={false}
                            className={style.calendar}
                            onPanelChange={onPanelChange}
                        />
                    </Col>
                    <Col flex="300px" style={ { padding:20}}>
                        <Alert
                                message={`当天24点后会自动生成报表，你也可以手动生成报表`}
                            />
                    </Col>
                </Row>
            </ModuleContent>
        </BasicLayout>
    );
};

export default GenerateReport;
