import React, { useState, useCallback, useEffect } from "react";
import {
    Input,
    Button,
    Switch,
    Form,
    Divider,
    Popconfirm,
    message,
    TimePicker,
} from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import { useParams, useHistory } from "react-router-dom";
import useForm from "../../hooks/useForm";
import moment from "moment";
import style from "./position.module.less";
import SpinBox from "../../components/SpinBox";
import DictSelect from "../../components/DictSelect";
import DataSelect from "../../components/DataSelect";
import Main from "../share/Main";
import API from "../../services/Api";
import PositionBoxList from "./PositionBoxList";
import DispatchBoxModel from "./DispatchBoxModel";

const { RangePicker } = TimePicker;

const PositionEdit = () => {
    let history = useHistory();
    const [adminEmpty, setAdminEmpty] = useState(true);
    const [ruleEmpty, setRuleEmpty] = useState(true);

    const [supportDisabledPeriod, setSupportDisabledPeriod] = useState(false);
    const [dispatchVisible, setDispatchVisible] = useState(false);
    const [stubs, setStubs] = useState(null);

    const onFormLoad = (form, data) => {
        form.setFieldsValue(data);

        if (data.staff) {
            setAdminEmpty(false);
        }

        if (data.rule) {
            setRuleEmpty(false);
        }

        if (data.supportDisabledPeriod) {
            setSupportDisabledPeriod(data.supportDisabledPeriod);
            let disablePeriod = JSON.parse(data.disablePeriod);
            if (disablePeriod && disablePeriod.length) {
                form.setFieldsValue({
                    crossDay: disablePeriod[0].crossDay,
                    time: [
                        moment(disablePeriod[0].startTime, "HH:mm:ss"),
                        moment(disablePeriod[0].endTime, "HH:mm:ss"),
                    ],
                });
            }
        }
    };

    const onBeforeSave = (form, values) => {
        if (values.supportDisabledPeriod) {
            let disablePeriod = [
                {
                    startTime: moment(values["time"][0]).format("HH:mm:ss"),
                    endTime: moment(values["time"][1]).format("HH:mm:ss"),
                    crossDay: values.crossDay ? values.crossDay : false,
                },
            ];
            values.disablePeriod = JSON.stringify(disablePeriod);
        }

        if (values.staff) {
            values.staffId = values.staff.id;
        }
        if (values.rule) {
            values.ruleId = values.rule.id;
        }
        if(values.deviceCategory) {
            values.deviceCategoryId = values.deviceCategory.id;
        }
        values.time = "";
    };

    const { formProps, loading, editMode, submitBtLayout, id } = useForm({
        id: useParams().id,
        formLoadUrl: "/positions/get",
        saveUrl: "/positions/save",
        saveMessage: "保存成功!",
        onBeforeSave: onBeforeSave,
        onFormLoad: onFormLoad,
    });

    const deleteData = () => {
        API.post("/positions/delete", { id: id }).then((res) => {
            message.success("删除成功");
            history.goBack();
        });
    };

    const resetAdmin = () => {
        API.post("/positions/resetAdmin", { id: id }).then((res) => {
            message.success("重置成功");
            document.location.reload();
        });
    };

    const resetRule = () => {
        API.post("/positions/resetRule", { id: id }).then((res) => {
            message.success("重置成功");
            document.location.reload();
        });
    };

    const openDispatchModel = () => {
        setDispatchVisible(true);
    };
    const modelCancel = () => {
        setDispatchVisible(false);
    };

    const onSupportDisabledChange = (e) => {
        setSupportDisabledPeriod(e);
    };

    const fetch = () => {
        if (editMode) {
            API.post("/stubs/queryAvailableStubs", { positionId: id }).then(
                (res) => {
                    const data = res.data;
                    setStubs(data);
                }
            );
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="场所编辑" />

                <Form {...formProps} className={style.form_content}>
                    <SpinBox loading={loading}>
                        <Form.Item
                            label="名称"
                            name="title"
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item label="描述" name="content">
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="设备类型"
                            name="deviceCategory"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DictSelect
                                selectFirst={!editMode}
                                disabled={editMode}
                                categoryKey="DEVICE_CATEGORY"
                            />
                        </Form.Item>

                        {editMode ? (
                            <Form.Item label="商户" name="merchant">
                                <DataSelect
                                    dataUrl="/merchants/listAll"
                                    disabled={true}
                                />
                            </Form.Item>
                        ) : (
                            ""
                        )}

                        <Form.Item label="对应规则" name="rule">
                            <DataSelect dataUrl="/rules/listAll" disabled={!editMode}/>
                        </Form.Item>

                        <Form.Item label="维护人员" name="staff">
                            <DataSelect dataUrl="/admins/staffListAll" />
                        </Form.Item>

                        <Form.Item label="维护人电话" name="phone">
                            <Input />
                        </Form.Item>

                        <Form.Item label="地址" name="address">
                            <Input />
                        </Form.Item>

                        <Form.Item label="备注" name="remark">
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="配置禁用时间"
                            name="supportDisabledPeriod"
                            valuePropName="checked"
                        >
                            <Switch onChange={onSupportDisabledChange} />
                        </Form.Item>

                        {supportDisabledPeriod ? (
                            <>
                                <Divider orientation="left" plain>
                                    禁用时间相关配置
                                </Divider>
                                <Form.Item
                                    label="时间段"
                                    name="time"
                                    rules={[
                                        { required: true, message: "时间必填" },
                                    ]}
                                >
                                    <RangePicker order={false} />
                                </Form.Item>

                                <Form.Item
                                    label="时间段跨天"
                                    name="crossDay"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            </>
                        ) : (
                            ""
                        )}

                        {Main.isMerchant() && (
                            <Form.Item {...submitBtLayout}>
                                {Main.isMerchant() ? (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={loading}
                                        style={{ marginRight: 8 }}
                                    >
                                        保存
                                    </Button>
                                ) : (
                                    ""
                                )}

                                {editMode && Main.isMerchant() ? (
                                    <Button
                                        type="primary"
                                        onClick={openDispatchModel}
                                        loading={loading}
                                        style={{ marginRight: 8 }}
                                    >
                                        投放设备
                                    </Button>
                                ) : (
                                    ""
                                )}

                                {editMode && Main.isMerchant() ? (
                                    <Popconfirm
                                        placement="bottomRight"
                                        title="确定要删除吗? 该操作无法恢复"
                                        onConfirm={deleteData}
                                        okText="删除"
                                        cancelText="取消"
                                    >
                                        <Button
                                            danger
                                            type="primary"
                                            style={{ marginRight: 8 }}
                                        >
                                            删除
                                        </Button>
                                    </Popconfirm>
                                ) : (
                                    ""
                                )}

                                {editMode && !adminEmpty && Main.isMerchant() ? (
                                    <Popconfirm
                                        placement="bottomRight"
                                        title="重置维护人员为空？"
                                        onConfirm={resetAdmin}
                                        okText="重置"
                                        cancelText="取消"
                                    >
                                        <Button
                                            danger
                                            style={{ marginRight: 8 }}
                                        >
                                            重置维护人员
                                        </Button>
                                    </Popconfirm>
                                ) : (
                                    ""
                                )}

                                {editMode && !ruleEmpty && Main.isMerchant() ? (
                                    <Popconfirm
                                        placement="bottomRight"
                                        title="重置规则为空？"
                                        onConfirm={resetRule}
                                        okText="重置"
                                        cancelText="取消"
                                    >
                                        <Button
                                            danger
                                            style={{ marginRight: 8 }}
                                        >
                                            重置规则
                                        </Button>
                                    </Popconfirm>
                                ) : (
                                    ""
                                )}
                            </Form.Item>
                        )}
                    </SpinBox>
                </Form>

                <DispatchBoxModel
                    disVisible={dispatchVisible}
                    handleCancel={modelCancel}
                    pid={id}
                    stubs={stubs}
                />
            </ModuleContent>
            <PositionBoxList id={id} />
        </BasicLayout>
    );
};

export default PositionEdit;
