import React, { useState } from "react";
import { Input, Button, Form } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader,
} from "../../layouts/basic/BasicLayout";
import { useParams } from "react-router-dom";
import useForm from "../../hooks/useForm";
import style from "./device.module.less";
import SpinBox from "../../components/SpinBox";
import DictSelect from "../../components/DictSelect";
import DataSelect from "../../components/DataSelect";
import Main from "../share/Main";

const DeviceEdit = () => {
    const onFormLoad = (form, data) => {
        form.setFieldsValue(data);
    };

    const onBeforeSave = (form, values) => {};

    const { formProps, loading, editMode, submitBtLayout, id } = useForm({
        id: useParams().id,
        formLoadUrl: "/devices/get",
        createUrl: "/devices/save",
        updateUrl: "/devices/update",
        saveMessage: "保存成功!",
        onBeforeSave: onBeforeSave,
        onFormLoad: onFormLoad,
    });

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="设备编辑" />

                <Form {...formProps} className={style.form_content}>
                    <SpinBox loading={loading}>
                        <Form.Item
                            label="名称"
                            name="title"
                            rules={[{ required: true, message: "名称必填" }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="唯一编码"
                            name="uniqueId"
                            rules={[
                                { required: true, message: "唯一编码必填" },
                            ]}
                        >
                            <Input disabled={editMode} />
                        </Form.Item>

                        {editMode ? (
                            <Form.Item label="商户" name="merchant">
                                <DataSelect
                                    dataUrl="/merchants/listAll"
                                    disabled={true}
                                />
                            </Form.Item>
                        ) : (
                            ""
                        )}
                        <Form.Item
                            label="状态"
                            name="status"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DictSelect
                                categoryKey="DEVICE_STATUS"
                                disabled={editMode}
                            />
                        </Form.Item>
                        <Form.Item
                            label="厂商"
                            name="vendor"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DictSelect
                                categoryKey="DEVICE_VENDOR"
                                disabled={editMode}
                            />
                        </Form.Item>

                        {editMode ? (
                            <>
                                <Form.Item
                                    label="最后更新时间"
                                    name="lastModifyTime"
                                >
                                    <Input disabled={editMode} />
                                </Form.Item>
                            </>
                        ) : (
                            ""
                        )}

                        <Form.Item label="备注" name="remark">
                            <Input />
                        </Form.Item>

                        <Form.Item {...submitBtLayout}>
                            {Main.isMerchant() ? (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    style={{ marginRight: 8 }}
                                >
                                    保存
                                </Button>
                            ) : (
                                ""
                            )}
                        </Form.Item>
                    </SpinBox>
                </Form>
            </ModuleContent>
        </BasicLayout>
    );
};

export default DeviceEdit;
