import React, { Component } from 'react';
import { Button, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import style from './component.module.less';



export const BlockAction = ({url, tip}) => {
    return (
        <Link to={url}>
            <Tooltip placement="top" title={tip}>
                <Button type="primary" shape="circle" size="small" icon={<PlusOutlined />} />
            </Tooltip>
        </Link>
    )
}


const BlockTitle = props => {
    return(
        <div className={style.block_title}>
            <h4 className={style.title}>{props.children}</h4>
            { props.action ? (
                <div className={style.block_action}>
                    {props.action}
                </div>
            ) : ""}
        </div>
    )
}

export default BlockTitle;

