import React, { useState, useEffect, useRef } from "react";
import style from "./report.module.less";
import Util from "../../services/Util";
import { Histogram } from "@ant-design/charts";

const StubInfoHistogramChart = ({ title, 
    data, dataKey, binWidth,
    columnStyleFormatter,
    tooltipFormatter,xAxisText,yAxisText }) => {

    const newData = data.map((item) => {
        return {
            value: item[dataKey]
        };
    });

    var config = {
        data: newData,
        binField: 'value',
        binWidth: binWidth,
        xAxis: {
            title: {
                text:xAxisText
            }
        },
        yAxis: {
            title: {
                text: yAxisText
            }
        },        
        columnStyle:columnStyleFormatter ,   
        tooltip: {
            formatter: tooltipFormatter,
            showMarkers: false,
            position: 'top',
        },
        interactions: [{ type: 'element-highlight' }],
    };
    return (
        <>
            <h3 style={{ marginTop: 20 }}>{title}</h3>
            <Histogram {...config} style={{ height: 300, width: "100%" }} />
        </>
    )
};
export default StubInfoHistogramChart;