import React, {useState, useEffect} from "react";
import {message, Button, Row, Col, Menu, Checkbox, Form, Space, Popconfirm} from "antd";
import {KeyOutlined, TeamOutlined, PlusOutlined} from "@ant-design/icons";
import BasicLayout, {
    ModuleContent,
} from "../../layouts/basic/BasicLayout";
import API from "../../services/Api";
import BlockTitle, {BlockAction} from "../../components/BlockTitle";
import SpinBox from "../../components/SpinBox";
import {defaultColumnLayout} from "../../configs/config";
import style from "./manager.module.less";

const RoleList = () => {

    const [roleLoading, setRoleLoading] = useState(true);
    const [authLoading, setAuthLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [roleAuths, setRoleAuths] = useState([]);
    const [selectedRoleItem, setSelectedRoleItem] = useState(undefined);

    useEffect(() => {
        fetch();
    }, [])


    const fetch = () => {
        setRoleLoading(true);
        API.post(`/roles/listAll`, {}).then(res => {
            setRoleLoading(false);
            setRoles(res.data);
            if (res.data && res.data.length > 0) {
                let item = res.data[0];
                if (selectedRoleItem) {
                    item = res.data.find(item => item.id === selectedRoleItem);
                    console.log(item);
                }
                queryRoleAuths(item);
            }
        }).catch(e => {
            setRoleLoading(false);
            setAuthLoading(false);
        });
    }

    const queryRoleAuths = (item, e) => {
        const roleAuths = item.authoritys;
        setSelectedRoleItem(item.id);
        setAuthLoading(true);

        API.post("/roles/allAuthoritys", {}).then(res => {

            const list = res.data;
            list.map((menuA, index) => {
                roleAuths.map((menu, index) => {
                    if (menu.id == menuA.id) {
                        menuA.checked = true;
                    }
                });
            });

            setRoleAuths(list);
            setAuthLoading(false);
        }).catch(e => {
            setAuthLoading(false);
        });
    }

    const handleChange = e => {
        roleAuths.map((menu, index) => {
            if (menu.id === e.target.id) {
                menu.checked = e.target.checked;
            }
        });
    };

    const handleSubmit = values => {
        let checked = [];

        roleAuths.map((menu, index) => {
            if (menu.checked == true) {
                checked.push(menu.id);
            }
        });
        if (selectedRoleItem) {
            API.post("/roles/saveAuthoritys", {
                id: selectedRoleItem,
                authoritys: checked.length > 0 ? checked : ""
            }).then(res => {
                message.success("保存成功");
                fetch();
            });
        }
    };

    const deleteAction = () => {
        API.post("/roles/delete", {
            id: selectedRoleItem,
        }).then(res => {
            message.success("删除成功");
            setSelectedRoleItem(undefined);
            fetch();
        });
    }


    return (
        <BasicLayout>
            <ModuleContent>
                <Row>
                    <Col {...defaultColumnLayout.col2}>
                        <div className={style.block}>
                            <BlockTitle
                                action={
                                    <BlockAction
                                        url="/manager/roleCreate"
                                        tip="新增角色"
                                    />
                                }
                            >
                                <TeamOutlined/> 角色列表
                            </BlockTitle>
                            <div className="m-t">
                                <SpinBox loading={roleLoading}>
                                    <Menu
                                        theme="light"
                                        mode="inline"
                                        style={{borderRight: "none"}}
                                        defaultSelectedKeys={["0"]}
                                    >
                                        {roles.map((item, index) => (
                                            <Menu.Item
                                                key={index}
                                                id={item.id}
                                                onClick={e => queryRoleAuths(item, e)}>
                                                {item.title}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                </SpinBox>
                            </div>
                        </div>
                    </Col>

                    <Col {...defaultColumnLayout.col2}>
                        <div className={style.blockR}>
                            <BlockTitle>
                                <KeyOutlined/> 权限编辑
                            </BlockTitle>
                            <div className={style.roleEditBox}>
                                <SpinBox loading={authLoading}>
                                    <Form onFinish={handleSubmit}>
                                        {roleAuths.map(
                                            (item, index) => (
                                                <Form.Item
                                                    key={item.id + item.checked}
                                                    className={style.authItem}>
                                                    <Checkbox
                                                        id={item.id}
                                                        key={index}
                                                        onChange={handleChange}
                                                        defaultChecked={
                                                            item.checked
                                                        }
                                                    >
                                                        {item.title} -{" "}
                                                        {item.id}
                                                    </Checkbox>
                                                </Form.Item>
                                            )
                                        )}
                                        <div className={style.footer}>
                                            <Space>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    保存
                                                </Button>
                                                <Popconfirm title="删除角色?"
                                                            onConfirm={deleteAction}
                                                            okText="是"
                                                            cancelText="否">
                                                    <Button
                                                        type="danger"
                                                    >
                                                        删除
                                                    </Button>
                                                </Popconfirm>
                                            </Space>
                                        </div>
                                    </Form>
                                </SpinBox>
                            </div>
                        </div>
                    </Col>
                </Row>
            </ModuleContent>
        </BasicLayout>
    );
};

export default RoleList;
