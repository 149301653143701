import React from 'react';
import { PictureFilled } from '@ant-design/icons';
import { Upload } from 'antd';
import API from '../services/Api';
import BraftEditor from 'braft-editor';
import { ContentUtils } from 'braft-utils';
import 'braft-editor/dist/index.css'
import styles from './component.module.less';

class HtmlEditor extends React.Component {

    state = {
        action: API.defaults.baseURL + '/files/uploadImage',
        editorState : BraftEditor.createEditorState(null),
        preEditorState : null
    }

    static getDerivedStateFromProps (props, state) {

        if (props.value && props.value.editorState !== state.preEditorState) {
            return {
                editorState: props.value.editorState,
                preEditorState: props.value.editorState
            }
        }
        return null
    }

    uploadHandler = (info) => {

        if (!info.file) {
            return false
        }

        if (info.file.status === 'done') {
            if(!this.state.editorState){
                this.state.editorState = BraftEditor.createEditorState(null);
            }

            this.setState({
                editorState : ContentUtils.insertMedias(this.state.editorState, [{
                    type: 'IMAGE',
                    url: info.file.response.url
                }])
            })

        }
    }

    handleChange = (editorState) => {
        this.setState({ editorState });

        const { onChange } = this.props;
        if (onChange) {
            onChange({
                editorState
            });
        }
    }

    render() {

        const { editorState, action } = this.state;

        const controls = [
            'undo', 'redo', 'separator',
            'font-size', 'line-height','separator',
            'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
            'text-indent', 'text-align', 'separator',
            'headings', 'list-ul', 'list-ol', 'separator',
            'link', 'hr', 'separator',
            'code', 'separator',
            'clear'
        ]

        const extendControls = [
            {
                key: 'antd-uploader',
                type: 'component',
                component: (
                    <Upload
                        accept="image/*"
                        action={action}
                        showUploadList={false}
                        onChange={this.uploadHandler}
                    >
                        <button type="button" className="control-item button upload-button" data-title="插入图片">
                            <PictureFilled />
                        </button>
                    </Upload>
                )
            }
        ]

        return (
            <div className={styles.html_editor}>
                <BraftEditor
                    value={editorState}
                    onChange={this.handleChange}
                    controls={controls}
                    extendControls={extendControls}
                />
            </div>
        );
    }
}

export default HtmlEditor;