import React, { useState } from 'react'
import { Radio } from 'antd'


const Segment = ({
    list,
    selectIndex = 0,
    onChange
}) =>{

    const onSegChange = e => {
        const status = e.target.value;
        onChange(status)
    }

    const defaultValue = list[selectIndex].id;

    return (
        <Radio.Group onChange={onSegChange} defaultValue={defaultValue}>
          {list.map((item, index) => (
            <Radio.Button key={index} value={item.id}>
              {item.title}
            </Radio.Button>
          ))}
        </Radio.Group>
    )
}

export default Segment
