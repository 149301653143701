import React, { useState } from "react";
import {
    Table,
    Button,
    Row,
    Col,
    Input,
    Divider,
    message,
    Popconfirm
} from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import { Link } from 'react-router-dom'
import useTable from "../../hooks/useTable";
import style from "./manager.module.less";
import API from "../../services/Api";


const AdminList = () => {
    const [queryWord, setQueryWord] = useState("");
    const [page, setPage] = useState(0);

    const columns = [
        {
            title: "用户名",
            dataIndex: "userName",
        },
        {
            title: "角色",
            dataIndex: ["adminRole", "title"],
        },
        {
            title: "名称",
            dataIndex: "realName",
        },
        {
            title: "手机",
            dataIndex: "mobile",
        },
        {
            title: "操作",
            dataIndex: "id",
            render: (text, record) => (
                <div>
                    <Link to={"/manager/AdminEdit/" + record.id}>编辑</Link>
                    <Divider type="vertical" />
                    <Popconfirm placement="bottomRight"
                                title="确定要删除吗? 该操作无法恢复"
                                onConfirm={()=>deleteAdmin(record.id)}
                                okText="删除"
                                cancelText="取消">
                    <a>
                        删除
                    </a>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const deleteAdmin = (id) => {
        API.post("/admins/delete", { id }).then((res) => {
            message.success("操作成功！");
            reloadTable();
        });
    };


    const { tableProps, reloadTable } = useTable({
        url: "/admins/query",
        queryParams: {
            page,
            queryWord,
        },
        onPageChange: (page) => {
            setPage(page);
        },
    });

    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="100px">
                        <Button type="primary">
                            <Link to="/manager/adminCreate">新增人员</Link>
                        </Button>
                    </Col>
                    <Col flex="auto" className={style.query_area}>
                        <Input.Search
                            placeholder="用户名/名称/手机"
                            onSearch={(g) => {
                                setQueryWord(g);
                                setPage(0);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} />
            </ModuleContent>
        </BasicLayout>
    );
};

export default AdminList;
