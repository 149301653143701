import React, { useState } from "react";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import Util from "../../services/Util";
import Main from "../share/Main";
import style from "./report.module.less";
import useTable from "../../hooks/useTable";
import Account from "../../services/Account";
import { DownloadOutlined } from "@ant-design/icons";
import DataSelect from "../../components/DataSelect.js";
import { Table, Row, Col, Button, Input, Tag } from "antd";
import DateRangeSelect from "../../components/DateRangeSelect";
import { Typography } from 'antd';

const { Text } = Typography;
const OrderPayDetail = () => {
    const [page, setPage] = useState(0);
    const [pid, setPid] = useState("");
    const [value, setValue] = useState("");
    const [queryWord, setQueryWord] = useState("");
    const [merchantId, setMerchantId] = useState("");
    const [dateRange, setDateRange] = useState(Util.dateRange(30, "days"));

    const columns = [
        {
            title: "商户",
            dataIndex: "merchantTitle",
            width: 120,
            render: (text, record) => <Text strong>{record.merchantTitle}</Text>,
        },
        {
            title: "场所",
            dataIndex: "positionTitle",
        },
        {
            title: "订单号",
            dataIndex: "orderNum",
            render: (text, record) => <Tag >{record.orderNum}</Tag>,
        },
        {
            title: "设备Id",
            dataIndex: "uniqueDeviceId",
            render: (text, record) => <Tag >{record.uniqueDeviceId}</Tag>,
        },
        {
            title: "设备名称",
            dataIndex: "uniqueDeviceName",
            render: (text, record) => <Tag >{record.uniqueDeviceName}</Tag>,
        },
        {
            title: "手机号",
            dataIndex: "userMobile",
        },

        {
            title: "下单时间",
            dataIndex: "createOrderTime",
        },
        {
            title: "订单结束时间",
            dataIndex: "finishOrderTime",
        },

        {
            title: "预付费",
            dataIndex: "preFee",
            render: (text, record) => (
                <Text code>{(record.preFee / 100).toFixed(2)}</Text>
            ),
        },
        {
            title: "收入",
            dataIndex: "fee",
            render: (text, record) => (
                <Text code>{(record.fee / 100).toFixed(2)}</Text>
            ),
        },
    ];

    const { tableProps, reloadTable } = useTable({
        url: "/report/orderReports/queryOrderPayDetail",
        pageSize: 10,
        queryParams: {
            page,
            queryWord,
            pid: pid ? pid.id : "",
            merchantId: merchantId ? merchantId.id : "",
            startDate: dateRange[0],
            endDate: dateRange[1],
        },
        onPageChange: (page) => {
            setPage(page);
        },
    });

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    };

    const onMerchantChange = (merchant) => {
        setMerchantId(merchant);
        setValue("");
        setPid("");
    };

    const changePosition = (position) => {
        setValue(position);
        setPid(position);
    };

    const downloadTemplate = () => {
        document.location.href =
            process.env.REACT_APP_API_HOST +
            `/report/orderReports/exportOrderPayDetail?accountId=${Account.info(
                "accountId"
            )}&merchantId=${merchantId ? merchantId.id : ""}&pid=${
                pid ? pid.id : ""
            }&queryWord=${queryWord}&startDate=${dateRange[0]}&endDate=${
                dateRange[1]
            }`;
    };

    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    {(Main.isMerchant() || Main.isAdmin) && (
                        <Col>
                            <Button type="dashed" icon={<DownloadOutlined />}>
                                <a onClick={downloadTemplate}>导出</a>
                            </Button>
                        </Col>
                    )}
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <DataSelect
                            allowClear
                            onChange={onMerchantChange}
                            dataUrl="/merchants/listAll"
                            placeholder="商户"
                            className={style.dict}
                        />
                        <DataSelect
                            allowClear
                            value={value}
                            onChange={changePosition}
                            parameter={merchantId ? merchantId.id : ""}
                            dataUrl="/positions/validHospitalsByMId"
                            placeholder="场所"
                            className={style.dict}
                        />
                        <Input.Search
                            placeholder="订单编号"
                            onSearch={(g) => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} />
            </ModuleContent>
        </BasicLayout>
    );
};

export default OrderPayDetail;
