import React, { useState, useCallback, useEffect } from "react";
import {
    Input,
    Button,
    Divider,
    Form,
    InputNumber,
    TimePicker,
    message,
    Radio,
    Popconfirm,
} from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader,
} from "../../layouts/basic/BasicLayout";
import { useParams, useHistory } from "react-router-dom";
import useForm from "../../hooks/useForm";
import style from "./position.module.less";
import SpinBox from "../../components/SpinBox";
import DictSelect from "../../components/DictSelect";
import API from "../../services/Api";
import Main from "../share/Main";
import TextArea from "antd/lib/input/TextArea";

const { RangePicker } = TimePicker;
const RuleEdit = () => {
    let history = useHistory();
    const [categoryId, setCategoryId] = useState("");
    const [durationFee, setDurationFee] = useState(0);

    const [type, setType] = useState("TIME");
    const types = [
        { id: "TIME", title: "按时" },
        { id: "FIXEDPRICE", title: "一口价" },
    ];

    const onFormLoad = (form, data) => {
        if (data.durationFee > 0) {
            setType("FIXEDPRICE");
        } else {
            setType("TIME");
        }

        form.setFieldsValue(data);
        setCategoryId(data.category.id);
        setDurationFee(data.durationFee);
    };

    const onBeforeSave = (form, values) => {
        values.categoryId = values.category.id;
    };

    const { formProps, loading, editMode, submitBtLayout, id } = useForm({
        id: useParams().id,
        formLoadUrl: "/rules/get",
        createUrl: "/rules/save",
        updateUrl: "/rules/update",
        saveMessage: "保存成功!",
        onBeforeSave: onBeforeSave,
        onFormLoad: onFormLoad,
    });

    const onCategoryChange = (dict) => {
        setCategoryId(dict.id);
    };

    const durationFeeChange = (num) => {
        setDurationFee(num);
    };

    const deleteRow = () => {
        API.post("/rules/delete", { id: id }).then((res) => {
            message.success("删除成功");
            history.goBack();
        });
    };

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="设备使用规则编辑" />

                <Form {...formProps} className={style.form_content}>
                    <SpinBox loading={loading}>
                        <Form.Item
                            label="名称"
                            name="title"
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="类型"
                            name="category"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DictSelect
                                disabled={editMode}
                                categoryKey="RULE_CATEGORY"
                                onChange={onCategoryChange}
                            />
                        </Form.Item>
                        <Form.Item label="备注" name="remark">
                            <TextArea />
                        </Form.Item>
                        <Divider />
                        {categoryId === "RULE_CATEGORY_FIXED_DURATION" ? (
                            <Form.Item label="类型" name="type">
                                <Radio.Group
                                    onChange={(e) => setType(e.target.value)}
                                    defaultValue={type}
                                    disabled={editMode}
                                >
                                    {types.map((item, index) => (
                                        <Radio value={item.id} key={index}>
                                            {item.title}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        ) : (
                            ""
                        )}
                        <Form.Item
                            label="预付费(分)"
                            name="preFee"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber min={1} />
                        </Form.Item>
                        <Form.Item
                            label="免费时间(分钟)"
                            name="freeTime"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        {categoryId === "RULE_CATEGORY_FIXED_DURATION" ? (
                            type !== "FIXEDPRICE" ? (
                                <>
                                    <Form.Item
                                        label="计费周期(分钟)"
                                        name="chargeUnit"
                                        rules={[
                                            {
                                                required: durationFee <= 0,
                                            },
                                        ]}
                                    >
                                        <InputNumber min={0} />
                                    </Form.Item>
                                    <Form.Item
                                        label="每计费周期费用(分)"
                                        name="chargeUnitFee"
                                        rules={[
                                            {
                                                required: durationFee <= 0,
                                            },
                                        ]}
                                    >
                                        <InputNumber min={0} />
                                    </Form.Item>{" "}
                                </>
                            ) : (
                                ""
                            )
                        ) : (
                            <>
                                <Form.Item
                                    label="计费周期(分钟)"
                                    name="chargeUnit"
                                    rules={[
                                        {
                                            required: durationFee <= 0,
                                        },
                                    ]}
                                >
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item
                                    label="每计费周期费用(分)"
                                    name="chargeUnitFee"
                                    rules={[
                                        {
                                            required: durationFee <= 0,
                                        },
                                    ]}
                                >
                                    <InputNumber min={0} />
                                </Form.Item>{" "}
                            </>
                        )}

                        {categoryId === "RULE_CATEGORY_FIXED_DURATION" ? (
                            type !== "FIXEDPRICE" ? (
                                <Divider orientation="left" plain>
                                    时长
                                </Divider>
                            ) : (
                                <Divider orientation="left" plain>
                                    时长与价格
                                </Divider>
                            )
                        ) : (
                            <Divider orientation="left" plain>
                                封顶扣费
                            </Divider>
                        )}
                        <Form.Item
                            label="时长(分钟)"
                            name="duration"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber min={0} />
                        </Form.Item>

                        {categoryId === "RULE_CATEGORY_FIXED_DURATION" ? (
                            // type !== "FIXEDPRICE" ? (
                            //     <Form.Item
                            //         label="周期扣费(分)"
                            //         name="chargeMaxFeePerDuration"
                            //         rules={[
                            //             {
                            //                 required: true,
                            //             },
                            //         ]}
                            //     >
                            //         <InputNumber min={0} />
                            //     </Form.Item>
                            // ) : (
                            //     ""
                            // )
                            ""
                        ) : (
                            <Form.Item
                                label="周期扣费(分)"
                                name="chargeMaxFeePerDuration"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber min={0} />
                            </Form.Item>
                        )}

                        {categoryId === "RULE_CATEGORY_FIXED_DURATION" ? (
                            <>
                                {type === "FIXEDPRICE" ? (
                                    <Form.Item
                                        label="一口价"
                                        name="durationFee"
                                        rules={[
                                            {
                                                required: type === "FIXEDPRICE",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            onChange={durationFeeChange}
                                        />
                                    </Form.Item>
                                ) : (
                                    ""
                                )}

                                {categoryId ===
                                "RULE_CATEGORY_FIXED_DURATION" ? (
                                    <Divider orientation="left" plain>
                                        逾期规则
                                    </Divider>
                                ) : (
                                    ""
                                )}

                                <Form.Item
                                    label="逾期扣费周期(分钟)"
                                    name="overdueChargeUnit"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <InputNumber min={0} />
                                </Form.Item>

                                <Form.Item
                                    label="逾期扣费金额(分)"
                                    name="overdueChargeFee"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <InputNumber min={0} />
                                </Form.Item>

                                <Form.Item
                                    label="逾期最多扣费(分)"
                                    name="overdueMaxChargeFee"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <InputNumber min={0} />
                                </Form.Item>
                            </>
                        ) : (
                            ""
                        )}
                        {/* <Form.Item
                                label="时间段"
                                name="time"
                                rules={[{ required: true, message: "时间必填" }]}
                            >
                                <RangePicker />
                            </Form.Item>

                            {categoryId === "BILLING_PLAN_BY_NUM" ? (
                                <Form.Item
                                    label="时间段跨天"
                                    name="crossDay"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            ) : (
                                ""
                            )}

                            {categoryId === "BILLING_PLAN_BY_NUM" ? (
                                <>
                                    <Form.Item
                                        label="每次费用"
                                        name="price"
                                        rules={[
                                            {
                                                required:
                                                    categoryId ===
                                                    "BILLING_PLAN_BY_NUM",
                                            },
                                        ]}
                                    >
                                        <InputNumber min={0.5} />
                                    </Form.Item>
                                    <Form.Item {...submitBtLayout}>
                                        <Alert
                                            type="info"
                                            message="金额小于0.5，微信商户平台不能转账。"
                                        ></Alert>
                                    </Form.Item>
                                </>
                            ) : (
                                ""
                            )}

                            {categoryId === "BILLING_PLAN_BY_TIME" ? (
                                <>
                                    <Form.Item
                                        label="免费时间"
                                        name="depositFreeHour"
                                        rules={[
                                            {
                                                required:
                                                    categoryId ===
                                                    "BILLING_PLAN_BY_TIME",
                                            },
                                        ]}
                                    >
                                        <InputNumber min={0} step={1} max={60} />
                                    </Form.Item>
                                    <Form.Item {...submitBtLayout}>
                                        <Alert
                                            type="info"
                                            message="押金扣除免费时间: 以分钟为单位，0表示马上扣，不能出现小数。"
                                        ></Alert>
                                    </Form.Item>

                                    <Form.Item
                                        label="扣费周期"
                                        name="depositInterval"
                                        rules={[
                                            {
                                                required:
                                                    categoryId ===
                                                    "BILLING_PLAN_BY_TIME",
                                            },
                                        ]}
                                    >
                                        <InputNumber min={0} step={1} />
                                    </Form.Item>
                                    <Form.Item {...submitBtLayout}>
                                        <Alert
                                            type="info"
                                            message="押金扣除周期: 以分钟为单位，60分钟表示每小时扣一次。"
                                        ></Alert>
                                    </Form.Item>

                                    <Form.Item
                                        label="扣费下限"
                                        name="depositMinValue"
                                        rules={[
                                            {
                                                required:
                                                    categoryId ===
                                                    "BILLING_PLAN_BY_TIME",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={0}
                                            step={0.1}
                                            max={positionsFee}
                                        />
                                    </Form.Item>
                                    <Form.Item {...submitBtLayout}>
                                        <Alert
                                            type="info"
                                            message="押金扣除下限: (单位:元),1:表示扣到只剩1块钱。"
                                        ></Alert>
                                    </Form.Item>

                                    <Form.Item
                                        label="押除金额"
                                        name="depositIntervalValue"
                                        rules={[
                                            {
                                                required:
                                                    categoryId ===
                                                    "BILLING_PLAN_BY_TIME",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={0.1}
                                            step={1}
                                            max={positionsFee}
                                        />
                                    </Form.Item>
                                    <Form.Item {...submitBtLayout}>
                                        <Alert
                                            type="info"
                                            message="押金扣除周期费用: (单位:元),1,表示每次扣1元，不能大于改场所的押金金额"
                                        ></Alert>
                                    </Form.Item>
                                </>
                            ) : (
                                ""
                            )} */}
                        <Form.Item {...submitBtLayout}>
                            {Main.isMerchant() ? (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    style={{ marginRight: 8 }}
                                >
                                    保存
                                </Button>
                            ) : (
                                ""
                            )}

                            {editMode && Main.isMerchant() ? (
                                <Popconfirm
                                    placement="bottomRight"
                                    title="确定要删除吗? 该操作无法恢复"
                                    onConfirm={deleteRow}
                                    okText="删除"
                                    cancelText="取消"
                                >
                                    <Button
                                        danger
                                        type="primary"
                                        style={{ marginRight: 8 }}
                                    >
                                        删除
                                    </Button>
                                </Popconfirm>
                            ) : (
                                ""
                            )}
                        </Form.Item>
                    </SpinBox>
                </Form>
            </ModuleContent>
        </BasicLayout>
    );
};

export default RuleEdit;
