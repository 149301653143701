import React, { useState } from "react";
import {
    Table,
    Row,
    Col,
    Input,
    notification,
    Divider,
    Popconfirm,
} from "antd";
import { Link, useParams } from "react-router-dom";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import useTable from "../../hooks/useTable";
import style from "./fee.module.less";
import DictSelect from "../../components/DictSelect";
import DateRangeSelect from "../../components/DateRangeSelect";
import Util from "../../services/Util";
import Main from "../share/Main";
import DataSelect from "../../components/DataSelect.js";
import API from "../../services/Api";
import { Typography, Tag } from 'antd';

const { Text } = Typography;
const WithdrawalList = () => {
    const [queryWord, setQueryWord] = useState("");
    const [page, setPage] = useState(0);
    const [status, setStatus] = useState(null);
    const [merchantId, setMerchantId] = useState(null);
    const [dateRange, setDateRange] = useState(Util.dateRange(3 * 365, "days"));
    let num = useParams().orderNumber;

    const queryPayResult = (paymentNo) => {
        API.post(`/withdrawals/queryPayResult`, { withdrawalNo: paymentNo }).then((res) => {
            if (paymentNo) {
                openNotificationWithIcon("success", res.data.msg, res.data.reason);
            } else {
                openNotificationWithIcon("error", '提示', '查询异常');
            }
        });
    };

    const openNotificationWithIcon = (type, msg, reason) => {
        notification[type]({
            message: msg,
            description: reason
        });
    };

    const columns = [
        {
            title: "提现单号",
            width: 200,
            dataIndex: ["invoiceNumber"],
            render: (text, record) => <Text strong>{record.invoiceNumber}</Text>,
        },
        {
            title: "订单号",
            width: 200,
            dataIndex: ["orderNumber"],
            render: (text, record) => <Tag color="default">{record.orderNumber}</Tag>,
        },
        {
            title: "收款商户",
            dataIndex: ["merchant", "title"],

        },
        {
            title: "收款账户",
            dataIndex: ["cardNumber"],
        },
        {
            title: "金额",
            dataIndex: ["fee"],
            render: (text, record) => <Text code>{(record.fee / 100).toFixed(2)}</Text>,
        },
        {
            title: "状态",
            dataIndex: ["status", "title"],
            render: (text, record) => {

                if (record.status.id === 'WITHDRAW_STATUS_SUCCESS') {
                    return <Tag color="success">
                        {record.status.title}
                    </Tag>
                } else if ("WITHDRAW_STATUS_SUBMIT" === record.status.id) {
                    return <Tag color="processing">
                        {record.status.title}
                    </Tag>
                } else {
                    return <Tag color="error">
                        {record.status.title}
                    </Tag>
                }
            }

        },
       
        {
            title: "创建时间",
            dataIndex: ["createTime"],
        },
        {
            title: "完成时间",
            dataIndex: ["finishTime"],
            render: (text, record) => (
                <div>
                    {record.finishTime ? record.finishTime : "-"}
                </div>
            )
        },
        {
            title: "操作",
            dataIndex: "id",
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <div>
                    <Link to={"/fee/withdrawalDetail/" + record.id}>
                        详情
                    </Link>
                    {record.paymentNo ? (
                        <>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确认查询?"
                                onConfirm={(e) =>
                                    queryPayResult(record.paymentNo)
                                }
                            >
                                <a>查询退款结果</a>
                            </Popconfirm>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            ),
        },
    ];

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    };

    const onStatusChange = (status) => {
        setStatus(status);
    };
    const onMerchantChange = (merchant) => {
        setMerchantId(merchant);
    };

    const { tableProps, reloadTable } = useTable({
        url: "/withDraws/query",
        pageSize: 10,
        queryParams: {
            page,
            queryWord: num ? num : queryWord,
            statusId: status ? status.id : "",
            merchantId: merchantId ? merchantId.id : "",
            startDate: dateRange[0],
            endDate: dateRange[1],
        },
        onPageChange: (page) => {
            setPage(page);
        },
    });

    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onStatusChange}
                            categoryKey="WITHDRAW_STATUS"
                            placeholder="状态"
                        />
                        <DataSelect
                            allowClear
                            onChange={onMerchantChange}
                            dataUrl="/merchants/listAll"
                            placeholder="商户"
                            className={style.dict}
                        />
                        <Input.Search
                            placeholder="订单编号"
                            onSearch={(g) => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} scroll={{ x: 1500, y: 800 }} />
            </ModuleContent>
        </BasicLayout>
    );
};

export default WithdrawalList;
