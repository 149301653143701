import axios from 'axios'
import qs from 'qs'
import { message } from 'antd'
import store from 'store'
import CryptoService from './cryptoService'

const API = axios.create({
    baseURL: process.env.REACT_APP_API_HOST
})

API.interceptors.request.use(
    function(request) {
        const params = { ...request.data } || {}
        const token = store.get('ob-token')
        const nonce = "123456"
        params["ob-channel"] = "ADMIN_CHANNEL_WEB_MANAGER";
        if (token) {
            params['ob-token'] = token

            if (nonce) {
                let timestamp = new Date().getTime()
                let sign = CryptoService.sign(
                    token,
                    timestamp.toString(),
                    nonce
                )
                params['sign'] = sign
                params['timestamp'] = timestamp
            }
        }

        for (let key in params) {
            let v = params[key]
            if (typeof v === 'object') {
                if (v && v.id) {
                    params[key + '.id'] = v.id
                    delete params[key]
                }
            }
        }

        request.data = qs.stringify(params,{ arrayFormat: 'repeat' })

        return request
    },
    function(error) {
        return Promise.reject(error)
    }
)

API.interceptors.response.use(
    function(response) {
        return response
    },
    function(error) {
        if (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    let baseName = process.env.REACT_APP_BASE_NAME
                    if (baseName.endsWith('/')) {
                        document.location.href = '/error/403'
                    } else {
                        document.location.href = baseName + '/error/403'
                    }
                } else if (error.response.status == 400) {
                    message.error('接口参数错误')
                } else if (error.response.status == 404) {
                    message.error('接口url不存在')
                } else {
                    message.error(error.response.data.message)
                }
                return Promise.reject(error)
            } else {
                message.error('网络异常! ')
                return Promise.reject(error)
            }
        }
    }
)

export default API
